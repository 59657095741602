<template>
  <div class="black-area">
    <div class="black-layout">
      <div class="black-section">
        <img
          class="arrow-svg"
          src="@/assets/arrow.svg"
          alt
          v-if="$store.state._loanRequest.calcResult == 0"
        />

        <div class="b-title">
          <div class="txt" v-if="$store.state._loanRequest.calcResult == 0">
            Ja, u kunt
            <span class="green">{{ $store.state._loanRequest.requestedLoanAmount | currency('€', 0) }}</span> verantwoord lenen via Leenattent.
          </div>
          <div class="txt" v-else-if="$store.state._loanRequest.calcResult == 1">
            Helaas, u kunt geen
            <span class="color-primary-default">{{ $store.state._loanRequest.requestedLoanAmount | currency('€', 0) }}</span> verantwoord lenen via Leenattent. U kunt maximaal <span class="green">{{ getCurrency($store.state._loanRequest.maxLoanAmount) }}</span> lenen.
          </div>
          <div class="txt" v-else>
            Helaas, u kunt geen
            <span class="color-primary-default">{{ $store.state._loanRequest.requestedLoanAmount | currency('€', 0) }}</span> verantwoord lenen via Leenattent.
          </div>
        </div>

        <div class="container-b">
          <div id="anchor-white-squares"></div>

          <div class="sep-30"></div>

          <div class="white-square">
            <!-- <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount > 30000"> -->
            <div class="green-label">
              <span>Advies van Leenattent</span>
            </div>

            <div class="title">
              <div class="txt">Persoonlijke lening</div>
            </div>

            <div class="bullets">
              <div class="arrow"></div>
              <div class="txt">Eenmalig geld lenen</div>
              <div class="arrow"></div>
              <div class="txt">Vaste rente</div>
              <div class="arrow"></div>
              <div class="txt">Boetevrij extra aflossen</div>
              <div class="arrow"></div>
              <div class="txt">Vaste maandtermijn</div>
              <div class="arrow"></div>
              <div class="txt">Vaste looptijd</div>
            </div>

            <btn-big class="allGreen" :height="80" :click="() => selectLoanType($store.state.loanType.PERSOONLIJKE)" v-anchor="'#anchor-grey-container'">
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>

          <div class="sep-50"></div>

          <div class="white-square">
            <!--
            <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount <= 30000">
              <span>Advies van Leenattent</span>
            </div>
            -->

            <div class="title">
              <div class="txt">Doorlopend krediet</div>
            </div>

            <div class="bullets">
              <div class="arrow"></div>
              <div class="txt">Geld opnemen tot een afgesproken limiet</div>
              <div class="arrow"></div>
              <div class="txt">Variabele rente</div>
              <div class="arrow"></div>
              <div class="txt">Boetevrij extra aflossen</div>
              <div class="arrow"></div>
              <div class="txt">Vast maandtermijn</div>
              <div class="arrow"></div>
              <div class="txt">Looptijd afhankelijk van de geldopnames</div>
            </div>

            <btn-big class="allGreen" :height="80" :click="() => selectLoanType($store.state.loanType.DOORLOPEND)" v-anchor="'#anchor-grey-container'">
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>

          <div class="sep-30"></div>

          <div id="anchor-grey-container"></div>

          <div class="sep-30"></div>

          <btn-big
            class="primary"
            :height="86"
            :click="() => { navTo('offerte'); }"
          >
            <div class="content">
              <img src="@/assets/money-w.svg" alt />
              <div class="txt">Offerte aanvragen</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>
      </div>

      <div class="grey-container" v-if="loanTypeModal == true">
        <img class="close" @click="loanTypeModal = false" v-anchor="'#anchor-white-squares'" src="@/assets/close-white.png" />

        <div class="contents">
          <input-dropdown
            v-if="loanTypeDropdown"
            :options="['Doorlopend krediet', 'Persoonlijke lening']"
            :init="true"
            v-model="$store.state._loanRequest.loanTypeTable"
          >
          </input-dropdown>

          <div v-else class="small-txt">{{ getLoanTypeName }}krediet</div>

          <div class="big-txt">
            <div class="txt">Wat wordt mijn maandlast?</div>
          </div>

          <loan-type-table></loan-type-table>
        </div>
      </div>

      <div class="black-section">
        <div class="cnt-container">
          <div class="contact-text">Meer informatie of advies?</div>

          <div class="icons">
            <img src="@/assets/white-phone.svg">
            <div class="txt">
              <a href="tel:023 539 5926">023 539 5926</a>
            </div>
            <img src="@/assets/white-email.svg">
            <div class="txt">
              <a href="mailto:info@leenattent.nl">info@leenattent.nl</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridRowGap: 2.5em;

  .black-area {
    .black-layout {
      color: getColor('whiteColor');
      background-color: getColor('grayDkColor');

      .black-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .arrow-svg {
          width: pxToEm(50);
          height: pxToEm(50);
          margin-top: pxToEm(50);
          transform: rotate(90deg);
          align-self: center;
        }

        .b-title {
          margin-top: pxToEm(40);

          .txt {
            font-size: pxToPercent(28);
            font-weight: 800;

            .green {
              color: getColor("greenDefault");
            }
          }
        }

        .container-b {
          width: 100%;
          min-width: pxToEm(260);
          max-width: pxToEm(500);
          margin: 0 auto;
          margin-top: pxToEm(20);

          .white-square {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
            color: getColor("textColor");
            background-color: getColor('bgColor');
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

            .green-label {
              width: pxToEm(210);
              height: pxToEm(43);
              position: absolute;
              margin-top: pxToEm(-20);
              color: getColor('whiteColor');
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: getColor("grayMidColor");

              span {
                font-size: pxToPercent(14);
              }
            }

            .title {
              margin: pxToEm(40) pxToEm(20) 0;

              .txt {
                font-size: pxToPercent(22);
                font-weight: 400;
              }
            }

            .bullets {
              margin: pxToEm(40) pxToEm(20);
              display: grid;
              grid-template-columns: pxToEm(30) 1fr;
              grid-row-gap: 1.5em;

              .arrow {
                width: pxToEm(5);
                height: pxToEm(5);
                margin-left: pxToEm(4);
                margin-top: pxToEm(8);
                transform: rotate(-45deg);
                border: solid getColor('blackColor');
                border-width: 0 pxToEm(1) pxToEm(1) 0;
              }

              .txt {
                font-size: pxToPercent(16);
              }

            }

            .btn-big {
              width: 100%;
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

              .content {
                margin-left: pxToEm(20);
                margin-right: pxToEm(20);

                .txt {
                  margin-right: pxToEm(20);
                  font-size: pxToPercent(16);
                  font-weight: 700;
                  text-align: left;
                }

                img {
                  width: pxToEm(36);
                  height: pxToEm(28);
                }
              }
            }
          }

          .btn-container {
            position: relative;
            z-index: 300;

            &.shadow {
              box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
            }
          }
        }

        .btn-big {
          margin: 0 auto;
          border-bottom-width: pxToEm(7);

          .content {
            margin: 0 pxToEm(20);

            .txt {
              margin-right: pxToEm(20);
              font-size: pxToPercent(16);
              font-weight: 700;
              text-align: left;
            }

            img {
              width: pxToEm(36);
              height: pxToEm(28);
            }
          }
        }

        .cnt-container {
          margin: pxToEm(60) 0 pxToEm(100);
          display: flex;
          flex-direction: column;
          align-items: center;

          .contact-text {
            font-size: pxToPercent(20);
          }

          .icons {
            margin-top: pxToEm(40);
            display: grid;
            grid-template-columns: pxToEm(36) 1fr;
            grid-row-gap: 2.5em;
            grid-column-gap: pxToEm(30);

            img {
              width: pxToEm(36);
              height: pxToEm(36);
            }

            .txt {
              font-size: pxToEm(18);
              font-weight: 600;
              align-self: center;
              text-align: left;
              color: getColor("greenDefault");

              a {
                color: getColor("greenDefault");
                cursor: pointer;
              }
            }
          }
        }
      }

      .grey-container {
        margin: pxToEm(-43) pxToEm(-20) 0;
        padding-top: pxToEm(60);
        padding-bottom: pxToEm(50);
        display: flex;
        flex-direction: column;
        color: getColor('whiteColor');
        background-color: getColor('grayMidColor');

        .close {
          width: pxToEm(16);
          height: pxToEm(16);
          padding: pxToEm(20) pxToEm(25);
          align-self: flex-end;
          cursor: pointer;
        }

        .contents {
          margin: 0 pxToEm(15);

          .input-dropdown {
            margin-top: pxToEm(20);
          }

          .small-txt {
            font-size: pxToPercent(18);
            font-weight: 700;
            text-align: center;
          }

          .big-txt {
            margin-top: pxToEm(20);
            margin-bottom: pxToEm(30);

            .txt {
              font-size: pxToPercent(36);
              text-align: center;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import inputDropdown from "@/components/common/input-dropdown";
  import loanTypeTable from "@/components/mobile/loan-type-table/loan-type-table.vue";

  export default {
    name: "blackArea",
    components: {
      btnBig,
      inputDropdown,
      loanTypeTable
    },
    data() {
      return {
        loanTypeDropdown: false,
        loanTypeModal: false
      };
    },
    methods: {
      navTo: function(url) {
        $store.state.storedData = true;
        this.$router.push(url);
      },
      selectLoanType(loanType) {
        this.loanTypeModal = true;
        $store.state._loanRequest.loanTypeTable = loanType;
      }
    },
    computed: {
      getLoanTypeName() {
        return $store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE ? "Persoonlijke " : "Doorlopend ";
      }
    }
  };
</script>
