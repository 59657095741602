var sumLenings = function (loanRequest) {
    var total = 0;
    var averageInterest = 0;
    var effectiveAnnualInterest = 0;

    for (var i = 0; i < loanRequest.prevLoans.length; i++) {
        total += loanRequest.prevLoans[i].amount;
    }

    for (var i = 0; i < loanRequest.prevLoans.length; i++) {
        averageInterest += loanRequest.prevLoans[i].amount / total * loanRequest.prevLoans[i].rate / 100;
    }

    if (total >= 50000) {
      effectiveAnnualInterest = 0.0340;
    } else if (total >= 25000) {
      effectiveAnnualInterest = 0.0360;
    } else if (total >= 15000) {
      effectiveAnnualInterest = 0.0410;
    } else if (total >= 10000) {
      effectiveAnnualInterest = 0.0580;
    } else {
      effectiveAnnualInterest = 0.0640;
    }

    loanRequest.currentLoans.total = total;
    loanRequest.currentLoans.averageInterest = averageInterest * 100;
    loanRequest.currentLoans.interestYear = averageInterest * total;

    loanRequest.newLoan.total = total;
    loanRequest.newLoan.effectiveAnnualInterest = (effectiveAnnualInterest * 100).toFixed(2);
    loanRequest.newLoan.interestYear = (effectiveAnnualInterest * total).toFixed(2);
    loanRequest.newLoan.interestSaving = (loanRequest.currentLoans.interestYear - loanRequest.newLoan.interestYear).toFixed(2);
    loanRequest.loanAmount = total;
}

export { sumLenings }
