function getComponentsInFolder(list) {
  var result = {};

  list.keys().forEach(el => {
    var name = el.replace(/\.\/|.vue/g, "").replace(/-[a-z]/gi, function (m) {
      return m[1].toUpperCase();
    });
    result[name] = list(el).default;
  });

  return result;
}

function strapiAssetUrl(path) {
  return (process.env.VUE_APP_ASSET_URL || 'https://la-api.thewiseminds.com/') + path;
}

function assignSet() {
  if (typeof Object.assignSet != 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assignSet", {
      value: function assignSet(target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null && nextSource != undefined) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                if (nextSource[nextKey] != null)
                  to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }
}

function goToAnchor(id, offset) {
  var $anchor,
    $scrollContainer;

  $anchor = document.querySelector(id);
  if ($anchor == null)
    return;
  $scrollContainer = document.querySelector('.scroll-container');
  offset = $scrollContainer.scrollTop + $anchor.getBoundingClientRect().top;
  $scrollContainer.scrollTop = offset;
}

function checkTab(tab, origin) {
  var res = true;
  for (var prop in tab) {
    var input = origin ? origin[tab[prop]] : tab[prop];

    if (prop == "ui") {
      return res;
    }

    if (input instanceof Array || input instanceof Object && input.ui == undefined) {
      /** need to check if prevloans is empty. Otherwise, since it's an array it will
       * try to enter and it gives undefined
       */
      if (input.length != 0) res = checkTab(input);
      else res = false;
    } else {
      if (input.ui.valid == false && input.ui.visible == true) {
        res = false;
        return res;
      }
    }
  }
  return res;
}

export {
  getComponentsInFolder,
  assignSet,
  goToAnchor,
  checkTab,
  strapiAssetUrl
};
