<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="pic" :style="{ 'background-image': 'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')' }"></div>
      <div class="section">
        <div class="container">
          <div class="title">{{block.Title}}</div>
          <div class="dash"></div>
          <div class="text" v-html="markdownToHTML(block.Subtitle)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");
    height: pxToEm(860);

    &.white {
      background-color: #fff;
    }

    .layout {
      justify-content: center;
      align-items: center;

      .pic {
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        right: 0;
        left: 50%;
        margin-left: pxToEm(-420);
        height: pxToEm(860);
      }

      .section {
        width: 100%;
        max-width: 1300px;
        position: absolute;
        top: pxToEm(80);

        .container {
          background-color: getColor("greyLight");
          width: pxToEm(452);
          height: pxToEm(373);
          padding: pxToEm(110) pxToEm(50) pxToEm(90) pxToEm(120);
          text-align: left;

          .title {
            font-size: pxToPercent(70);
            line-height: 1em;
            font-weight: 800;
          }

          .dash {
            background-color: getColor("primaryLight");
            width: pxtoEm(123);
            height: pxToEm(11);
            margin: pxToEm(20) 0 pxToEm(20) pxToEm(5);
          }

          .text {
            font-size: pxToPercent(36);
          }
        }
      }
    }
  }
</style>

<script>
  import {strapiAssetUrl} from "@/JS/helpers";
  import {marked} from 'marked'

  export default {
    name: "DesktopLargeImageHeader",
    methods: {
      markdownToHTML: function(markdown) {
        return marked(markdown);
      },
      strapiAssetUrl: function (path) {
        return strapiAssetUrl(path)
      }
    },
    props: {
      block: Object
    }
  };
</script>
