<template>
  <div>
    <mob-logo class="area"></mob-logo>
    <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
      <div class="layout">
        <div class="section">
          <div class="container-a">
            <div class="title">
              <div class="txt">{{block.Title}}</div>
            </div>

            <div class="dash"></div>
          </div>
        </div>

        <div class="pic" :style="{ 'background-image': 'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')'}"></div>

        <div class="section" v-if="block.Subtitle">
          <div class="container-a">
            <div class="text">
              {{block.Subtitle}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    padding-bottom: pxToEm(10);
    background-color: getColor("greyLight");

    &.white {
      background-color: #fff;
    }

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            font-size: pxToPercent(28);
            font-weight: 800;
            line-height: 1em;
          }

          .dash {
            width: pxToEm(91);
            height: pxToEm(8);
            margin-top: pxToEm(10);
            background-color: getColor("primaryLight");
          }

          .text {
            font-size: pxToPercent(28);
            font-weight: 300;
          }
        }
      }

      .pic {
        width: 100%;
        height: 62vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
</style>

<script>
  import {strapiAssetUrl} from "@/JS/helpers";
  import mobLogo from "@/components/mobile/mob-header-logo";

  export default {
    name: "MobileLargeImageHeader",
    methods: {
      strapiAssetUrl: function (path) {
        return strapiAssetUrl(path)
      }
    },
    components: {
      mobLogo
    },
    props: {
      block: Object
    }
  };
</script>
