<template>
  <div
    class="input-num input"
    :class="{
      disabled: ui.disabled == true ? true : false,
      initialized: ui.init == true || hasFocus == true ? true : false,
      invalid: ui.valid != true ? true : false
    }"
  >
    <input
      class="input-txt"
      type="text"
      ref="input"
      :disabled="ui.disabled"
      :minlength="ui.minLen"
      :maxlength="ui.maxLen"
      :min="ui.min"
      :placeholder="ui.placeholder"
      :value="internalValue"
      :pad="ui.pad"
      @focus="onFocus"
      @click="onFocus"
      @keypress="onKeyPress($event)"
      @blur="onBlur"
    >
  </div>
</template>

<style lang="less" scoped>
@border-width: pxToEm(2);

.input-num {
  overflow: hidden;
  border: @border-width solid getColor("grayLtColor");
  -moz-appearance: none;
  -webkit-appearance: none;

  .input-txt {
    width: 100%;
    height: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    text-align: center;
    color: getColor("grayLtColor");
    border: none;

    &:focus {
      outline: none;
    }
  }

  &.invalid {
    .formcheck & {
      border: @border-width solid getColor("redColor");
    }
  }

  &.initialized .input-txt {
    color: getColor("textColor");
  }

  &.disabled .input-txt {
    color: getColor("disabledText");
    background-color: getColor("disabledBg");
    cursor: default;
  }
}
</style>

<script>

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');
        if (this.length >= targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

export default {
  props: {
    disabled: { type: Boolean, default: null },
    init: { type: Boolean, default: null },
    valid: { type: Boolean, default: null },
    min: { type: Number, default: null },
    minLen: {
      type: Number,
      default: null
    },
    maxLen: {
      type: Number,
      default: null
    },
    minVal: {
      type: Number,
      default: null
    },
    maxVal: {
      type: Number,
      default: null
    },
    pad: {
      type: String,
      default: null
    },
    format: { type: Boolean, default: null },
    step: {
      type: Number,
      default: null
    },
    unit: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    }
  },
  data: function() {
    return {
      ui: {
        disabled: false,
        init: false,
        valid: null,
        format: true
      },
      hasFocus: false,
      internalValue: null
    };
  },
  created: function() {
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = true;
    }
    Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
    this.localeDecimalChar = ','; //(1.1).toLocaleString().replace(/[\s1]/g, ""); // Disabled decimal char detection per client request.
    this.localeThousandChar = this.localeDecimalChar == "." ? "," : ".";
    if (this.ui.unit == "%" || this.ui.unit == "€") this.ui.format = true;
    this.hasDecimals = !(this.ui.step != null && this.ui.step >= 1);
    this.rxClearFormat = new RegExp("[s$%€" + (this.localeThousandChar == "." ? "\\" : "") + this.localeThousandChar + "]", "gi");
    this.rxKeyInputValid = new RegExp("[0-9" + (this.localeDecimalChar == "." ? "\\" : "") + this.localeDecimalChar + "]", "i");

    this.setValue(this.value);
  },
  watch: {
    value: function(newVal, oldVal) {
      this.setValue(newVal);
    }
  },
  beforeDestroy(){
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = false;
    }
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    stepRound: function(val, step) {
      return Number(Math.round(val / step) * step);
    },
    validateValue: function(val) {
      var result = val;
      var ui = this.ui;
      if (val != null && val !== "") {
        if (ui.minVal != null && val < ui.minVal) result = ui.minVal;
        if (ui.maxVal != null && val > ui.maxVal) result = ui.maxVal;
        if (ui.step != null) result = this.stepRound(result, ui.step);
      }
      return result;
    },
    formatValue: function(val, unit, format) {
      var result = val;
      if (val != null && val !== "") {
        var valToLocStr = val.toLocaleString('nl-NL', { maximumFractionDigits: 2 });
        var unitStr = unit ? unit : "";
        switch (unit) {
          case "€":
            result = unit + valToLocStr;
            break;
          default:
            if (format) result = valToLocStr + unitStr;
            else result = this.ui.pad && this.ui.maxLen ? val.toString().padStart(this.ui.maxLen, this.ui.pad) : val.toString();
            break;
        }
      }
      return result;
    },
    setValue: function(val) {
      if (val != null && val){
        Object.assignSet(this.ui, this.value.ui);
        this.value = val;
      }
      if (this.ui.init) {
        this.internalValue = this.formatValue(this.validateValue(val), this.ui.unit, this.ui.format);
      } 
      else this.internalValue = "";
    },
    clearFormat: function(val) {
      var result = val;
      if (val != null && val !== "") result = val.replace(this.rxClearFormat, "").trim();
      return result;
    },
    onFocus: function() {
      this.hasFocus = true;
      if (!this.isDisabled) this.internalValue = this.clearFormat(this.internalValue);
    },
    onKeyPress: function(event) {
      var key = event.key;
      if (this.rxKeyInputValid.test(key)) {
        if (key == this.localeDecimalChar && (!this.hasDecimals || this.$refs.input.value.indexOf(this.localeDecimalChar) >= 0)) event.preventDefault();
      } else if (event.code == "Enter") {
        event.preventDefault();
        this.$refs.input.blur();
      } else event.preventDefault();
    },
    onBlur: function() {
      this.hasFocus = false;
      var val = this.clearFormat(this.$refs.input.value);
      var emitValue = NaN;
      this.ui.init = false;
      this.ui.valid = false;
      if (val !== "") {
        val = val.replace(',', '.');
        emitValue = this.validateValue(Number.parseFloat(val));
        this.internalValue = this.formatValue(emitValue, this.ui.unit, this.ui.format);
        this.ui.init = true;
        this.ui.valid = true;
      }
      this.$emit("input", emitValue);
    }
  }
};
</script>
