var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"area"},[_c('div',{staticClass:"layout"},[(
        _vm.block.Image &&
        _vm.block.Image.data &&
        _vm.block.Image.data.attributes &&
        _vm.block.Image.data.attributes.url
      )?_c('div',{staticClass:"pic",style:({
        'background-image':
          'url(' + _vm.strapiAssetUrl(_vm.block.Image.data.attributes.url) + ')',
      })},[_c('div',{staticClass:"filter"})]):_vm._e(),_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.block.Title))]),_vm._v(" "+_vm._s(_vm.block.Title2)+" ")]),_c('div',{staticClass:"btn-container",on:{"click":_vm.openPanel}},[_c('btn-big',{staticClass:"primary",attrs:{"height":68}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.block.Label))]),_c('img',{attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])])],1)]),_c('div',{staticClass:"slide-panel",class:{
        on: _vm.panel,
      }},[_c('div',{staticClass:"arrow",on:{"click":_vm.closePanel}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"63.773","height":"51.096","viewBox":"0 0 63.773 51.096"}},[_c('g',{attrs:{"transform":"translate(0)"}},[_c('g',{attrs:{"fill":"none","stroke":"#3a3a3a","stroke-width":"3","transform":"translate(12.677 0)"}},[_c('ellipse',{attrs:{"cx":"25.548","cy":"25.548","stroke":"none","rx":"25.548","ry":"25.548"}}),_c('ellipse',{attrs:{"cx":"25.548","cy":"25.548","fill":"none","rx":"24.048","ry":"24.048"}})]),_c('path',{attrs:{"fill":"none","stroke":"#3a3a3a","stroke-width":"3","d":"M7232.648,1306h47.439","transform":"translate(-7232.648 -1280.452)"}}),_c('path',{attrs:{"fill":"none","stroke":"#3a3a3a","stroke-width":"3","d":"M0,.176,19.721,0l-.132,19.766","transform":"translate(34.564 11.603) rotate(45)"}})])])]),_c('div',{staticClass:"panel-content"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.block.Label)+" ")])]),_c('div',{staticClass:"panel-buttons"},_vm._l((_vm.block.Links),function(link,i){return (_vm.block.Links)?_c('div',{key:i,staticClass:"button",on:{"click":function($event){return _vm.navTo(link.url)}}},[_c('div',{staticClass:"icon"},[(
                  link.Image &&
                  link.Image.data &&
                  link.Image.data.attributes &&
                  link.Image.data.attributes.url
                )?_c('img',{staticStyle:{"padding":"30px"},attrs:{"src":_vm.strapiAssetUrl(link.Image.data.attributes.url)}}):_vm._e()]),_c('div',{staticClass:"btn-txt"},[_vm._v(" "+_vm._s(link.label)+" ")]),_vm._m(0,true)]):_vm._e()}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-arrow"},[_c('div')])}]

export { render, staticRenderFns }