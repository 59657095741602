import { render, staticRenderFns } from "./trustpilot.vue?vue&type=template&id=81a962ce&scoped=true&"
import script from "./trustpilot.vue?vue&type=script&lang=js&"
export * from "./trustpilot.vue?vue&type=script&lang=js&"
import style0 from "./trustpilot.vue?vue&type=style&index=0&id=81a962ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a962ce",
  null
  
)

export default component.exports