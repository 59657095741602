<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="title" v-if="block.Title">{{block.Title}}</div>

        <div class="expandable-items">
          <div
            class="exp-item"
            v-for="faq_item in block.faq_group.data.attributes.faq_items.data"
          >
            <div class="exp-item-title" v-expand>
              <div v-if="faq_item.attributes.Question" class="left">{{faq_item.attributes.Question}}</div>

              <div class="right">
                <div class="arrow"></div>
              </div>
            </div>

            <div class="exp-item-text">

              <div class="exp-item-body" v-if="faq_item.attributes.Answer" v-html="markdownToHTML(faq_item.attributes.Answer)">
              </div>

              <a v-if="faq_item.attributes.Link" :href="faq_item.attributes.Link">
                <btn-big class="secondary-pink" :width="231" :height="74">
                  <div class="content">
                    <div class="txt">Lees meer</div>
                    <img src="@/assets/arrow.svg" alt/>
                  </div>
                </btn-big>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    align-items: center;
    padding: 0 20px;
    
    .section {
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-bottom: pxToEm(110);
      width: pxToEm(992);

      @media (max-width: 1000px) {
        width: initial;
        //margin: 0 pxToEm(50);
      }

      .title {
        font-size: pxToEm(36);
        margin: pxToEm(55) 0 pxToEm(15) 0;

        @media (max-width: 768px) {
          font-size: pxToEm(24);
          line-height: pxToEm(16);
          text-decoration: underline;
          font-weight: 700;
          margin-top: pxToEm(60);
          margin-bottom: pxToEm(10);
        }
      }

      .expandable-items {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .exp-item {
          height: pxToEm(125);
          overflow: hidden;
          max-width: pxToEm(992);
          background-color: #fff;
          padding: 0 0 0 pxToEm(50);
          margin-bottom: pxToEm(50);
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);

          @media (max-width: 768px) {
            padding: 0 0 0 pxToEm(10);
            height: pxToEm(94);
            margin-bottom: pxToEm(30);
          }

          a {
            text-decoration: none;
          }

          .btn-big {
            margin: 50px 0;

            .content {
              margin: 0 20px;

              .txt {
                font-size: pxToPercent(18);
                font-weight: 800;
              }

              img {
                width: pxToEm(36);
                height: pxToEm(29);
              }
            }
          }

          &.expanded {
            height: auto;

            .exp-item-title .arrow {
              transform: rotate(-135deg);
            }
          }

          &:last-child {
            @media (min-width: 1000px) {
              margin: 0;
            }
          }

          .exp-item-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: pxToEm(125);
            cursor: pointer;

            @media (max-width: 768px) {
              height: pxToEm(94);
            }

            .left {
              flex: 2 1 auto;

              font-size: pxToEm(24);

              @media (max-width: 768px) {
                font-size: pxToEm(18);
                font-weight: 600;
              }
            }

            .right {
              width: pxToEm(100);
              height: pxToEm(100);
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .arrow {
              border: solid black;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 5px;
              transform: rotate(45deg);
              position: relative;
            }
          }

          .exp-item-text {
            max-width: 768px;

            .exp-item-body {
              font-size: pxToPercent(18);
              line-height: 2.2em;

              @media (max-width: 768px) {
                font-size: pxToPercent(16);
              }
            }

            &:last-child {
              margin-bottom: pxToEm(50);
            }

            .exp-item-subtitle {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {marked} from 'marked'
import btnBig from "@/components/common/btn-big";

export default {
  name: 'FaqSectionBlock',
  props: {
    block: Object
  },
  components: {
    btnBig
  },
  methods: {
    markdownToHTML: function(markdown) {
      return marked(markdown);
    }
  }
}
</script>
