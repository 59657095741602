<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="form" v-if="divNum == 2" :class="{ formcheck: form0.state == false }">
      <div v-if="formSent == false">
        <div class="form-title">
          <div class="txt">{{block.Title}}</div>
        </div>
        <div id="form-error"></div>
        <div class="sep-70" v-show="form0.state == false"></div>
        <div
          class="error"
          v-show="form0.state == false"
        >Er zijn enkele velden niet correct ingevuld.
        </div>
        <div class="sep-80"></div>

        <div class="content">
          <div class="row-112-b text-input">
            <div class="col">
              <div class="txt-label">Voorletter(s)</div>
              <div class="inputs">
                <input-text v-model="form0.fields.firstName"></input-text>
              </div>
            </div>
            <div class="col">
              <div class="txt-label">Tussenvoegsel</div>
              <div class="inputs">
                <input-text v-model="form0.fields.middleName"></input-text>
              </div>
            </div>
            <div class="col">
              <div class="txt-label">Achternaam</div>
              <div class="inputs">
                <input-text v-model="form0.fields.lastName"></input-text>
              </div>
            </div>
          </div>

          <div class="row-fw-b radio-input">
            <div class="col">
              <div class="txt-label">Geslacht</div>
              <div class="inputs">
                <input-radio v-model="form0.fields.gender">
                  <span slot="option1">Man</span>
                  <span slot="option2">Vrouw</span>
                </input-radio>
              </div>
            </div>
          </div>

          <div class="row-211-b text-input">
            <div class="col">
              <div class="txt-label">Straat</div>
              <div class="inputs">
                <input-text v-model="form0.fields.streetName"></input-text>
              </div>
            </div>
            <div class="col">
              <div class="txt-label">Huisnummer</div>
              <div class="inputs">
                <input-text v-model="form0.fields.houseNumber"></input-text>
              </div>
            </div>
            <div class="col">
              <div class="txt-label">Toevoeging</div>
              <div class="inputs">
                <input-text v-model="form0.fields.affix"></input-text>
              </div>
            </div>
          </div>

          <div class="row-13-b text-input">
            <div class="col">
              <div class="txt-label">Postcode</div>
              <div class="inputs">
                <input-text v-model="form0.fields.postCode"></input-text>
              </div>
            </div>
            <div class="col">
              <div class="txt-label">Plaats</div>
              <div class="inputs">
                <input-text v-model="form0.fields.city"></input-text>
              </div>
            </div>
          </div>

          <div class="row-11-b">
            <div class="col">
              <div class="txt-label">Vaste telefoonnummer (Optioneel)</div>
              <div class="inputs">
                <input-text v-model="form0.fields.fixedPhoneNumber"></input-text>
              </div>
            </div>
            <div class="col">
              <div class="txt-label">Mobiele telefoonnummer</div>
              <div class="inputs">
                <input-text v-model="form0.fields.mobilePhoneNumber"></input-text>
              </div>
            </div>
          </div>

          <div class="row-11-b">
            <div class="col">
              <div class="txt-label">E-mail</div>
              <div class="inputs">
                <input-text v-model="form0.fields.emailAddress"></input-text>
              </div>
            </div>
            <div class="col"></div>
          </div>

          <div class="row-fw-b textarea-input">
            <div class="col">
              <div class="txt-label">Opmerkingen (Optioneel)</div>
              <div class="inputs">
                <textarea v-model="form0.fields.comments"></textarea>
              </div>
            </div>
          </div>
        </div>

        <btn-big class="primary form-btn" :width="550" :height="92" :click="submitForm">
          <div class="content">
            <div class="txt">Ik stuur u de gegevens van deze klant</div>
            <img src="@/assets/arrow.svg" alt>
          </div>
        </btn-big>
      </div>

      <form-message v-else-if="formSent == true" :option="'ABOUT'"></form-message>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;

.area {
  min-height: pxToEm(755);
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .form {
    background-color: getColor("whiteColor");
    margin: pxToEm(0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 pxToEm(100);

    .error {
      color: getColor("redColor");
      font-style: italic;
    }

    .form-title {
      margin-top: pxToEm(60);

      .txt {
        font-size: pxToEm(60);
        font-weight: 300;
      }
    }

    .content {
      max-width: 766px;
      width: 100%;
      display: grid;
      grid-row-gap: @gridRowGap;
      text-align: left;

      .input-radio {
        width: pxToEm(320);
      }

      .input-text {
        height: pxToEm(60);
      }

      textarea {
        width: 100%;
        height: pxToEm(210);
        text-align: left;
        font-size: pxToPercent(18);
        background-color: getColor("whiteColor");
        border: @border-width solid getColor("grayLtColor");
        resize: none;
      }

      .txt-label {
        font-size: pxToPercent(18);
        font-weight: 300;
        line-height: 2.2em;
      }

      .row-11-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        .col {
          &:first-child {
            margin-right: @gridColHalfGap;
          }

          &:last-child {
            margin-left: @gridColHalfGap;
            margin-right: 0;
          }
        }

        .input-text-300 {
          width: pxToEm(296);
        }
      }

      .row-13-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;

        .col {
          margin-right: @gridColGap;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .row-112-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        align-items: flex-start;

        .col {
          &:nth-child(1) {
            margin-right: @gridColGap;
          }

          &:nth-child(2) {
            margin-right: @gridColHalfGap;
          }

          &:nth-last-child(1) {
            margin-left: @gridColHalfGap;
            margin-right: 0;
          }
        }
      }

      .row-211-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;

        .col {
          &:nth-child(1) {
            margin-right: @gridColHalfGap;
          }

          &:nth-last-child(2) {
            margin-left: @gridColHalfGap;
            margin-right: @gridColHalfGap;
          }

          &:nth-last-child(1) {
            margin-left: @gridColHalfGap;
            margin-right: 0;
          }
        }
      }

      .row-fw-b {
        height: pxToEm(100);

        &.radio-input {
          height: pxToEm(80);
        }

        &.textarea-input {
          height: pxToEm(280);
        }
      }
    }

    .form-btn {
      margin: 0 auto;
      margin-top: pxToEm(70);

      .content {
        margin-left: pxToEm(30);
        margin-right: pxToEm(30);
        display: flex;
        flex-flow: row;

        .txt {
          font-size: pxToPercent(20);
          font-weight: 700;
        }

        img {
          width: pxToEm(48);
          height: pxToEm(38);
        }
      }
    }

    .form-message {
      margin: pxToEm(50) 0 pxToEm(70);
    }

    .white-button {
      width: pxToEm(50);
      height: pxToEm(50);
      position: absolute;
      left: 50%;
      bottom: pxToEm(-25);
      transform: translateX(-50%);
      align-self: center;
      border-radius: 50%;
      background-color: getColor("whiteColor");
      box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
      z-index: 100;
      cursor: pointer;

      .arrow-cont {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow {
          display: inline-block;
          position: relative;
          transform: rotate(-135deg);
          margin-top: pxToEm(5);
          padding: pxToEm(5);
          border: solid getColor("primaryLight");
          border-width: 0 pxToEm(3) pxToEm(3) 0;
        }
      }
    }
  }
}

</style>

<script>
import btnBig from "@/components/common/btn-big";
import inputDropdown from "@/components/common/input-dropdown";
import inputRadio from "@/components/common/input-radio";
import inputText from "@/components/common/input-text";
import formMessage from "@/components/common/form-message";
import {_form0} from "@/data/aboutData.js";
import {goToAnchor, checkTab} from "@/JS/helpers";
import {dataToMail, mails, registerXml} from "@/JS/mail";

export default {
  name: "DesktopSignupSectionBlock",
  components: {
    btnBig,
    inputDropdown,
    inputRadio,
    inputText,
    formMessage
  },
  props: {
    block: Object
  },
  data() {
    return {
      dropdown1value: null,
      $div1: null,
      divNum: 2,
      workActive: false,
      form0: {
        fields: new _form0(),
        state: undefined
      },
      formSent: false,
    };
  },
  mounted() {
    this.$div1 = document.querySelector(".form");
  },
  methods: {
    submitForm() {
      const verkoperId = this.$route.query.verkoperId
      const subagentId = this.$route.query.subagentId

      this.form0.state = checkTab(this.form0.fields);
      if (this.form0.state == false) {
        goToAnchor("#form-error");
      } else {
        dataToMail({
          mailTo: mails.info,
          mailSubject: "About Leenattent",
          mailData: this.form0.fields
        }, verkoperId, subagentId)
          .then(res => {
            //CODE FOR MAIL SENT /////////////
            this.form0.fields = new _form0();
            this.form0.state = undefined;
            this.formSent = true;
            goToAnchor("#form-top");
            // console.log(res);
          })
          .catch(rej => {
            //CODE FOR MAIL NOT SENT /////////////
            this.formSent = false;
            // console.log(rej);
          });

        let xmlData = {};
        for (let key in this.form0.fields) {
          var field = this.form0.fields[key].toString()
          xmlData[key] = field
        }

        if (xmlData['gender']) {
          if (xmlData['gender'] === 'male') xmlData['gender'] = 'M';
          if (xmlData['gender'] === 'female') xmlData['gender'] = 'V';
        }

        if (verkoperId !== '' && subagentId !== '') {
          xmlData = { ...xmlData, verkoperId, subagentId }
        }

        registerXml(xmlData).then(res => {
          console.log(res)
        }).catch(err => {
          console.log({'error': err})
        })
      }
    }
  }
};
</script>
