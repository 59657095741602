<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="title">Beschikbare leningsoorten</div>

        <div class="container">
          <div class="white-square">
            <!-- <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount > 30000"> -->
            <div class="green-label">
              <span>Advies van Leenattent</span>
            </div>
            
            <div class="container">
              <div class="title">
                <div class="txt">Persoonlijke lening</div>
              </div>

              <div class="bullets">
                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Eenmalig geld lenen</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste rente</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste looptijd</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste maandtermijn</span>
                </div>
              </div>
            </div>

            <btn-big
              class="secondary"
              :height="106"
              :click="
                () => {
                  selectLoanType($store.state.loanType.PERSOONLIJKE);
                }
              "
              v-anchor="'#anchor-grey-container'"
            >
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>

          <div class="white-square">
            <!--
            <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount <= 30000">
              <span>Advies van Leenattent</span>
            </div>
            -->

            <div class="container">
              <div class="title">
                <div class="txt">Doorlopend krediet</div>
              </div>

              <div class="bullets">
                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Flexibele kredietvorm</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Geld opnemen tot een afgesproken limiet</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Geld opnemen tot een afgesproken limiet</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vervolgens weer opnieuw geld opnemen</span>
                </div>
              </div>
            </div>

            <btn-big
              class="secondary"
              :height="106"
              :click="
                () => {
                  selectLoanType($store.state.loanType.DOORLOPEND);
                }
              "
              v-anchor="'#anchor-grey-container'"
            >
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>
        </div>

        <div class="grey-container" v-if="loanTypeModal == true">
          <img class="close" @click="loanTypeModal = false" src="@/assets/close.png">

          <div class="small-txt">{{ getLoanTypeName }}krediet</div>

          <div class="big-txt">Wat wordt mijn maandlast?</div>

          <loan-type-table></loan-type-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  .layout {
    .section {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: pxToEm(50);

      >.title {
        font-size: pxToPercent(36);
        font-weight: 700;
      }

      .subtitle {
        margin-top: 25px;
        font-size: pxToPercent(20);
        line-height: 2.3em;
        width: 800px;
      }

      >.container {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        max-width: 894px;
        width: 100%;

        .white-square {
          width: 417px;
          height: 443px;
          background-color: #fff;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
          position: relative;

          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }

          .green-label {
            width: pxToEm(210);
            height: pxToEm(43);
            background-color: getColor("greenDefault");
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position:absolute;
            top: pxToEm(-20);
            left:50%;
            transform:translateX(-50%);

            span {
              font-size: pxToPercent(14);
            }
          }

          > .container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: left;

            >.title {
              flex: 1 1 auto;              
              margin-top: pxToEm(60);
              .txt{
                font-size: pxToPercent(24);
              }
            }

            .bullets {
              flex: 1 1 auto;
              line-height: 2.7em;
              margin-top:pxToEm(30);

              .bullet {
                .arrow {
                  border: solid #000;
                  border-width: 0 1px 1px 0;
                  display: inline-block;
                  padding: 4px;
                  transform: rotate(-45deg);
                }

                span {
                  margin-left: pxToEm(20);
                  font-size: pxToPercent(16);
                }
              }
            }
          }
          .btn-big {
            width: 100%;
            height: pxToEm(104);
            flex: 0 1 auto;
            position: absolute;
            bottom: 0;

            .content {
              margin-left: pxToEm(40);
              margin-right: pxToEm(40);

              .txt {
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              img {
                width: pxToEm(48);
                height: pxToEm(38);
              }
            }
          }
        }
      }

      .grey-container {
        flex-direction: column;
        max-width: 1156px;
        width: 100%;
        border: 1px solid #c0c0c0;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
        margin-top: 80px;
        display: flex;

        .close {
          width: 38px;
          height: 21px;
          align-self: flex-end;
          margin: pxToEm(20) pxToEm(30);
          cursor: pointer;
        }

        .small-txt {
          font-size: pxToPercent(18);
          font-weight: 700;
        }

        .big-txt {
          font-size: pxToPercent(36);
          margin-top: pxToEm(10);
        }

        .table {
          margin: pxToEm(35) pxToEm(95);
        }
      }
    }
  }
}
</style>
<style lang="less">
.btn-big {
  .content {
    .txt {
      font-size: pxToPercent(28);
    }
  }
}
</style>
<script>
import btnBig from "@/components/common/btn-big";
import loanTypeTable from "@/components/common/loan-type-table.vue";

export default {
  name: "offerteBody",
  components: {
    btnBig,
    loanTypeTable
  },
  data() {
    return {
      loanTypeModal: false
    };
  },
  computed: {
    getLoanTypeName() {
      return $store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE ? "Persoonlijke " : "Doorlopend ";
    }
  },
  methods: {
    selectLoanType(loanType) {
      this.loanTypeModal = true;
      $store.state._loanRequest.loanTypeTable = loanType;
    },
    test() {
      return $store.state._loanRequest.loanAmount * 0.1;
    }
  }
};
</script>