<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title">
            <div class="txt">
              Beschikbare leningsoorten
            </div>
          </div>

          <div class="subtitle">
            <div class="txt">
              Op basis van uw gegevens laten wij de beschikbare leningsoorten zien. Vanuit
              Leenattent geven wij een vrijblijvend advies.
            </div>
          </div>
        </div>

        <div class="container-b">
          <div class="sep-25"></div>

          <div id="anchor-white-squares"></div>

          <div class="sep-25"></div>

          <div class="white-square">
            <!-- <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount > 30000"> -->
            <div class="green-label">
              <span>Advies van Leenattent</span>
            </div>

            <div class="title">
              <div class="txt">Persoonlijke lening</div>
            </div>

            <div class="bullets">
              <div class="arrow"></div>
              <div class="txt">Eenmalig geld lenen</div>
              <div class="arrow"></div>
              <div class="txt">Vaste rente</div>
              <div class="arrow"></div>
              <div class="txt">Boetevrij extra aflossen</div>
              <div class="arrow"></div>
              <div class="txt">Vaste maandtermijn</div>
              <div class="arrow"></div>
              <div class="txt">Vaste looptijd</div>
            </div>

            <btn-big class="secondary" :height="80" :click="() => selectLoanType($store.state.loanType.PERSOONLIJKE)" v-anchor="'#anchor-grey-container'">
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>

          <div class="sep-50"></div>

          <div class="white-square">
            <!--
            <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount <= 30000">
              <span>Advies van Leenattent</span>
            </div>
            -->

            <div class="title">
              <div class="txt">Doorlopend krediet</div>
            </div>

            <div class="bullets">
              <div class="arrow"></div>
              <div class="txt">Geld opnemen tot een afgesproken limiet</div>
              <div class="arrow"></div>
              <div class="txt">Variabele rente</div>
              <div class="arrow"></div>
              <div class="txt">Boetevrij extra aflossen</div>
              <div class="arrow"></div>
              <div class="txt">Vast maandtermijn</div>
              <div class="arrow"></div>
              <div class="txt">Looptijd afhankelijk van de geldopnames</div>
            </div>

            <btn-big class="secondary" :height="80" :click="() => selectLoanType($store.state.loanType.DOORLOPEND)" v-anchor="'#anchor-grey-container'">
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>
        </div>
      </div>

      <div class="grey-container" v-if="loanTypeModal == true">
        <div id="anchor-grey-container"></div>

        <img class="close" @click="loanTypeModal = false" src="@/assets/close-white.png" v-anchor="'#anchor-white-squares'">

        <div class="contents">
          <div class="small-txt">
            <div class="txt">{{ getLoanTypeName }} krediet</div>
          </div>

          <div class="big-txt">
            <div class="txt">Wat wordt mijn maandlast?</div>
          </div>

          <loan-type-table></loan-type-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            .txt {
              font-size: pxToPercent(20);
              font-weight: 700;
            }
          }

          .subtitle {
            margin-top: pxToEm(10);

            .txt {
              font-size: pxToPercent(16);
              line-height: 2.3em;
            }
          }
        }

        .container-b {
          width: 100%;
          min-width: pxToEm(260);
          max-width: pxToEm(500);
          margin: 0 auto;

          .white-square {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
            background-color: getColor('bgColor');
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

            .green-label {
              width: pxToEm(210);
              height: pxToEm(44);
              position: absolute;
              margin-top: pxToEm(-22);
              color: getColor('whiteColor');
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: getColor("greenDefault");

              span {
                font-size: pxToPercent(14);
              }
            }

            .title {
              margin: pxToEm(40) pxToEm(20) 0;

              .txt {
                font-size: pxToPercent(22);
                font-weight: 400;
              }
            }

            .bullets {
              margin: pxToEm(40) pxToEm(20);
              display: grid;
              grid-template-columns: pxToEm(30) 1fr;
              grid-row-gap: 1.5em;

              .arrow {
                width: pxToEm(5);
                height: pxToEm(5);
                margin-left: pxToEm(4);
                margin-top: pxToEm(8);
                transform: rotate(-45deg);
                border: solid getColor('blackColor');
                border-width: 0 pxToEm(1) pxToEm(1) 0;
              }

              .txt {
                font-size: pxToPercent(16);
              }

            }

            .btn-big {
              width: 100%;
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

              .content {
                margin-left: pxToEm(20);
                margin-right: pxToEm(20);

                .txt {
                  margin-right: pxToEm(20);
                  font-size: pxToPercent(16);
                  font-weight: 700;
                  text-align: left;
                }

                img {
                  width: pxToEm(36);
                  height: pxToEm(28);
                }
              }
            }
          }
        }
      }

      .grey-container {
        margin: pxToEm(35) 0 pxToEm(25);
        padding: pxToEm(10) 0 pxToEm(50);
        display: flex;
        flex-direction: column;
        color: getColor('whiteColor');
        background-color: getColor('grayMidColor');

        .close {
          width: pxToEm(16);
          height: pxToEm(16);
          padding: pxToEm(20) pxToEm(25);
          align-self: flex-end;
          cursor: pointer;
        }

        .contents {
          margin: 0 pxToEm(15);

          .small-txt {
            .txt {
              font-size: pxToPercent(18);
              font-weight: 700;
              text-align: center;
            }
          }

          .big-txt {
            margin-top: pxToEm(20);
            margin-bottom: pxToEm(30);

            .txt {
              font-size: pxToPercent(36);
              text-align: center;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import loanTypeTable from "@/components/mobile/loan-type-table/loan-type-table.vue";

  export default {
    name: "offerteBody",
    components: {
      btnBig,
      loanTypeTable
    },
    data() {
      return {
        $modal: null,
        loanTypeModal: false
      };
    },
    computed: {
      getLoanTypeName() {
        return $store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE ? "Persoonlijke " : "Doorlopend ";
      }
    },
    methods: {
      selectLoanType(loanType) {
        this.loanTypeModal = true;
        $store.state._loanRequest.loanTypeTable = loanType;
      }
    }
  };
</script>
