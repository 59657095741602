// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');
        if (this.length >= targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

var mails = {
  contact: process.env.VUE_APP_CONTACT_MAIL || "mohamed@alkaduhimi.nl", // Contact page.
  info: process.env.VUE_APP_INFO_MAIL || "mohamed@alkaduhimi.nl", // Footer, About and Home (Advies nodig?)
  maex: process.env.VUE_APP_MAEX_MAIL || "mohamed@alkaduhimi.nl" // XML data.
};

var mailURLBase = process.env.VUE_APP_PHP_URL || "https://leenattent.nl/php/";

function sendMail(data, url) {
    data = JSON.stringify(data);

    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, true);
    xhttp.setRequestHeader("Content-type", "text/plain");
    xhttp.send(data);
    return new Promise((resolve, reject) => {
        xhttp.onload = () => resolve(xhttp.responseText);
        xhttp.onerror = () => reject(xhttp.statusText);
    });
}

function dataToMail(form, verkoperId = null, subagentId = null) {
    var url = mailURLBase + "sendMail.php";

    var data = [];
    for (var prop in form.mailData) {
        var field = form.mailData[prop];
        if(field instanceof Date) {
            var val = new String(field.toLocaleDateString());
            val.ui = field.ui;
            data.push([field.ui.label, val]);
        } else
            data.push([field.ui.label, field]);
    }

    data.sort((a, b) => {
        return a[1].ui.order - b[1].ui.order;
    });

    form.mailData = data;

    if (verkoperId && subagentId) {
      data.push(['verkoperId', verkoperId])
      data.push(['subagentId', subagentId])
    }

    form = JSON.stringify(form);

    return new Promise((resolve, reject) => {
        sendMail(form, url).then((res) => {
            resolve(res);
        }).catch((rej) => {
            reject(rej);
        });
    });
}

function getDate(value) {
  var date = new Date(Date.parse(value));
  value = date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear();
  return value;
}

function collectValidateSend($store, $route, tabs, prevLoans, checkTab, loanRequest) { // NOTE: when changing submitForm() also update mobile version
    const verkoperId = $route.query.verkoperId || ''
    const subagentId = $route.query.subagentId || ''

    let foundInvalid = false;
    let dataToMail = {};

    if (verkoperId !== '' && subagentId !== '') {
      dataToMail = { verkoperId, subagentId }
    }
    for (let tab in tabs) {
      for (let field of tabs[tab].fields) {
        if (tabs[tab].origin[field].ui.init == true) {
          let label = tabs[tab].origin[field].ui.label;
          let value = tabs[tab].origin[field].toString();

          // 1. Uw gewenste lening
          if (label === 'loanOption') {
            value = $store.state.funnelOptions[value];

            // Clear prevLoans if not chosen for specific item
            if (value !== 'Lening(en) oversluiten') {
              loanRequest.prevLoans = [];
            }
          }
          if (label === 'approvedLoanAmount') value = Math.round(value/100)*100;
          if (label === 'loanType') value = $store.state.loanTypeOptions[value];

          // 2. Huidige leningen (optional)
          if (label === 'prevLoans') {
              value = [];
              for (let prevLoan of loanRequest.prevLoans) {
                  value.push( {
                    'name': prevLoan.name,
                    'amount': prevLoan.amount,
                    'rate': prevLoan.rate,
                    'ransom': prevLoan.ransom
                  } );
              }
          }

          // 2. Uw situatie
          if (label === 'incomeSource') value = $store.state.incomeSourceDropdown[value];
          if (label === 'dateService') value = getDate(value);

          // 3. Uw persoonlijke gegevens
          if (label === 'gender') {
            if (value === 'male') value = 'M';
            if (value === 'female') value = 'V';
          }

          if (label === 'livingState') value = $store.state.livingStateDropdown[value];

          if (label === 'partnerGender') {
            if (value === 'male') value = 'M';
            if (value === 'female') value = 'V';
          }

          if (label === 'birthDate') value = getDate(value);
          if (label === 'partnerBirthDate') value = getDate(value);
          if (label === 'partnerIncomeSource') value = $store.state.incomeSourceDropdown[value];
          if (label === 'partnerDateService') value = getDate(value);

          // 4. Uw contactgegevens
          // is OK!

          dataToMail[label] = value;
        }
      }

      tabs[tab].state = checkTab(tabs[tab].fields, tabs[tab].origin);

      let el = document.querySelector(tabs[tab].id);
      if (tabs[tab].state == false) {
        let tabTest = tab.replace('tab', '') -1;
        $store.state.formsOfferte[ tabTest ].expand = true;
      }
    }

    // if (formsValid == true) {
      XMLtoMail(dataToMail)
        .then(res => {
          let result = JSON.parse(res);

          // Not successful
          if ( result.success == "false" ) {

            let tabsErrors = {};
            let counter = 0;
            let scrollToTab = '#form-top-custom';
            for (let tab in tabs) {

              let errorsHtml = '';
              tabs[tab].fields.forEach(function (item, index) {
                  // If error has been found
                  if ( result.fieldErrors.hasOwnProperty(item) ) {
                      errorsHtml = errorsHtml + result.fieldErrors[item] + '\n';

                      if ( scrollToTab == '#form-top-custom' ) {
                        scrollToTab = '#form-error-' + tab;
                      }
                  }
              });


              if ( errorsHtml !== '' ) {
                  tabs[tab].state = false;

                  if (tab === 'tab2') {
                    errorsHtml = errorsHtml.replace(/,/g, '\n');
                  }

                  tabsErrors[tab] = errorsHtml;
                  $store.state.formsOfferte[counter].expand = true;
              }

              counter++;
            }

            $store.commit('changeTabErrors', tabsErrors );

            // Scroll
            setTimeout( function() {
              document.querySelector(scrollToTab).scrollIntoView({behavior: 'smooth'});
            }, 500);

            // Successful
          } else if ( result.success == "true" ) {
            $store.state.formSent = true;

            // Scroll
            setTimeout( function() {
              document.querySelector('#form-top-custom').scrollIntoView({behavior: 'smooth'});
            }, 500);
          }

        })
        .catch(rej => {
          //CODE FOR MAIL NOT SENT /////////////
          ////this.formSent = false;
          // console.log(rej);
        });
}

function XMLtoMail(data) {
    let url = mailURLBase + "sendXML.php";

    return new Promise((resolve, reject) => {
        sendMail(data, url).then((res) => {
            resolve(res);
        }).catch((rej) => {
            reject(rej);
        });
    });
}

function registerXml(data) {
  let url = mailURLBase + "registerXML.php";

  return new Promise((resolve, reject) => {
      sendMail(data, url).then((res) => {
          resolve(res);
      }).catch((rej) => {
          reject(rej);
      });
  });
}

export { collectValidateSend, getDate, dataToMail, XMLtoMail, registerXml, mails };
