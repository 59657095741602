<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="team">
            <div class="pic" :style="{ 'background-image': 'url(' + strapiAssetUrl(block.image.data.attributes.url) + ')' }"></div>
            <div class="content">
              <div class="title">5 Redenen om geld te lenen bij Leenattent</div>

              <div class="paragraphs" v-for="paragraph in block.Content">
                <div class="paragraph">
                  <div class="p-subtitle">{{paragraph.Title}}</div>
                  <div class="p-content" v-html="markdownToHTML(paragraph.text)"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    .section {
      margin: pxToEm(25) pxToEm(10);
      text-align: left;

      .container-a {
        padding: pxToEm(10) pxToEm(25);

        .team {
          height: auto;

          .pic {
            height: pxToEm(180);
            background-size: cover;
            background-repeat: no-repeat;
            background-position-y: 75%;
          }

          .content {
            background-color: getColor("whiteColor");
            height: auto;
            padding: 0 pxToEm(20);
            padding-top: pxToEm(20);
            padding-bottom: pxToEm(20);
            margin-bottom: pxToEm(20);

            .title {
              font-size: pxToEm(35);
              font-weight: 700;
              text-decoration: underline;
            }

            .paragraphs {
              .paragraph {
                margin-top: pxToEm(20);

                .p-subtitle {
                  font-size: pxToEm(25);
                  font-weight: 300;
                }

                .p-content {
                  font-size: pxToEm(16);
                  line-height: 2.1em;
                  margin-top: pxToEm(20);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width:768px)and(min-width:768px){
  .area .layout .section .container-a .team .content .title{
    font-size: pxToEm(30);
  }
}
</style>

<script>
  import {marked} from 'marked'
  import {strapiAssetUrl} from "@/JS/helpers";

  export default {
    name: "MobileBoxedSectionBlock",
    methods: {
      markdownToHTML: function(markdown) {
        return marked(markdown);
      },
      strapiAssetUrl: function (path) {
        return strapiAssetUrl(path)
      }
    },
    props: {
      block: Object
    }
  };
</script>
