<template>
  <div class="area" v-if="block">
    <div class="layout">
      <div
        class="pic"
         v-if="block.Image && block.Image.data && block.Image.data.attributes && block.Image.data.attributes.url"
          :style="{ 'background-image': 'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')' }"
      ></div>
      <div class="section">
        <div class="title">
          {{block.Title}}
        </div>

        <router-link :to="block.Link.url">
          <btn-big class="primary" :height="61" :width="233">
            <div class="content">
              <div class="txt">{{block.Link.label}}</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  min-height: pxToEm(500);
  .layout {
    .pic {
      background-size: cover;
      background-repeat: no-repeat;
      height: pxToEm(297);
      background-position-y: bottom;
    }

    .section {
      margin: pxToEm(25) pxToEm(50);
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: pxToPercent(28);
        font-weight: 700;
        margin-bottom: pxToEm(15);
        line-height: 1em;
      }

      a {
        text-decoration: none;
      }

      .btn-big {
        position: relative;
        flex: 0 1 auto;

        .txt {
          font-size: pxToPercent(16);
          font-weight: 700;
          text-align: left;
        }

        .content {
          margin-left: pxToEm(17);
          margin-right: pxToEm(17);
        }

        img {
          width: pxToEm(33);
          height: pxToEm(27);
        }
      }
    }
  }
}
</style>

<script>
import headerImg from "@/assets/homepage-second-header.jpg";
import btnBig from "@/components/common/btn-big";
import { strapiAssetUrl } from "@/JS/helpers";

export default {
  name: "mobSecondHeader",
  props: {
    block: Object,
  },
  components: {
    btnBig,
  },
  data() {
    return {
      headerImg,
    };
  },
    methods:{
     strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    },
  }
};
</script>
