<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="info texts">
          <div id="anchor-info-top" style="position:absolute; top:0px;"></div>
          <div class="title" v-if="block.Title">{{block.Title}}</div>
          <div class="paragraphs">
            <div
              class="paragraph"
              v-for="(paragraph, index) in block.Paragraphs"
              :key="`paragraph-${index}`"
            >
              <div class="text-cont">
                <div class="subtitle" v-if="paragraph.Title"> <div>{{paragraph.Title}}</div></div>
                <div class="text" v-html="markdownToHTML(paragraph.text)">
                </div>
              </div>
            </div>
            <div class="paragraph">
              <div @click="toggleInfo(true)" v-if="!infoArea" style="float:left">
                <btn-big class="secondary-pink" :width="231" :height="74" style="float:'left'">
                  <div class="content">
                    <div class="txt">Lees meer</div>
                    <img src="@/assets/arrow.svg" alt/>
                  </div>
                </btn-big>
              </div>
            </div>

            <div class="expanded" v-if="infoArea">
              <div
                class="paragraph"
                v-for="(paragraph, index) in block.ReadMore"
                :key="`readmore-${index}`"
              >
                <div class="subtitle" v-if="paragraph.Title">
                  <div>{{paragraph.Title}}</div>
                </div>
                <div class="text-cont" v-if="paragraph.text">
                  <div class="text" v-html="markdownToHTML(paragraph.text)">
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div
          class="white-button"
          v-if="infoArea"
          @click="toggleInfo(false)"
          v-anchor="'#anchor-info-top'"
        >
          <div class="arrow-cont">
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    align-items: center;
    padding:0px 20px;

    .section {
      margin: 0 pxToEm(50);
      margin-top: pxtoEm(50);
      max-width: pxToEm(1160);
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width:100%;
      @media (max-width:999px) {
        margin: 0;
      }

      &.expanded {
        height: auto;
      }

      .info {
        text-align: left;
        margin: pxToEm(70);

        @media (max-width:999px) {
          margin: pxToEm(70) 0;
        }

        margin-top: 0;

        .title {
          font-size: pxToEm(60);
          font-weight: 700;
          text-decoration: underline;

          @media (max-width:999px) {
            font-size: pxToEm(36);
            //padding: 0 pxToEm(15);
            padding: 0;
          }
        }

        .btn-big {
          margin: 50px 0;

          .content {
            margin: 0 20px;

            @media (max-width:999px) {
              margin: 0 0;
            }

            .txt {
              font-size: pxToPercent(18);
              font-weight: 800;
            }

            img {
              width: pxToEm(36);
              height: pxToEm(29);
            }
          }
        }

        .paragraphs {
          margin-top: pxToEm(30);

          .paragraph {
            margin-bottom: pxToEm(60);
            padding: 0;

            @media (min-width: 1000px) {
              padding: 0;
              width: 778px;
            }

            .subtitle {
              margin-bottom: pxToEm(30);

              div {
                font-size: pxToEm(36);
                font-weight: 700;
              }
            }

            .text-cont {
              margin-bottom: pxToEm(30);

              .text {
                font-size: pxToEm(18);
                line-height: 2.1em;
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width:450px){
  .area .layout .section .info .paragraphs .paragraph .subtitle {
    div {
      font-size: pxToEm(30);
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import {marked} from 'marked'

export default {
  name: 'CenterTextBlockReadMore',
  components: {
    btnBig
  },
  data() {
    return {
      infoArea: false
    }
  },
  methods: {
    toggleInfo: function (state) {
      this.infoArea = state;
    },
    markdownToHTML: function(markdown) {
      return marked(markdown);
    }
  },
  props: {
    block: Object
  },
}
</script>
