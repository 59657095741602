<template>
  <div class="area">
    <div class="layout">
      <div
        class="pic"
        v-if="
          block.Image &&
          block.Image.data &&
          block.Image.data.attributes &&
          block.Image.data.attributes.url
        "
        :style="{
          'background-image':
            'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')',
        }"
      >
        <div class="filter"></div>
      </div>
      <div class="section">
        <div class="title">
          <span>{{ block.Title }}</span>
          {{ block.Title2 }}
        </div>

        <div class="btn-container" @click="openPanel">
          <btn-big class="primary" :height="68">
            <div class="content">
              <div class="txt">{{ block.Label }}</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>
      </div>

      <div
        class="slide-panel"
        :class="{
          on: panel,
        }"
      >
        <div class="arrow" @click="closePanel">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="63.773"
            height="51.096"
            viewBox="0 0 63.773 51.096"
          >
            <g transform="translate(0)">
              <g
                fill="none"
                stroke="#3a3a3a"
                stroke-width="3"
                transform="translate(12.677 0)"
              >
                <ellipse
                  cx="25.548"
                  cy="25.548"
                  stroke="none"
                  rx="25.548"
                  ry="25.548"
                ></ellipse>
                <ellipse
                  cx="25.548"
                  cy="25.548"
                  fill="none"
                  rx="24.048"
                  ry="24.048"
                ></ellipse>
              </g>
              <path
                fill="none"
                stroke="#3a3a3a"
                stroke-width="3"
                d="M7232.648,1306h47.439"
                transform="translate(-7232.648 -1280.452)"
              ></path>
              <path
                fill="none"
                stroke="#3a3a3a"
                stroke-width="3"
                d="M0,.176,19.721,0l-.132,19.766"
                transform="translate(34.564 11.603) rotate(45)"
              ></path>
            </g>
          </svg>
        </div>

        <div class="panel-content">
          <div class="title">
            <div class="txt">
              {{ block.Label }}
            </div>
          </div>

          <div class="panel-buttons">
            <div
              class="button"
              v-if="block.Links"
              v-for="(link, i) in block.Links"
              :key="i"
              @click="navTo(link.url)"
            >
              <div class="icon">
                <img
                  style="padding:30px"
                  v-if="
                    link.Image &&
                    link.Image.data &&
                    link.Image.data.attributes &&
                    link.Image.data.attributes.url
                  "
                  :src="strapiAssetUrl(link.Image.data.attributes.url)"
                />
              </div>

              <div class="btn-txt">
                 {{ link.label }}
              </div>

              <div class="btn-arrow">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  height: pxToEm(628);

  .layout {
    .pic {
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute;
      background-position-x: 40%;

      .filter {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .section {
      margin: pxToEm(50) pxToEm(25);

      .title {
        font-size: pxToPercent(36);
        color: getColor("whiteColor");
        text-align: left;
        font-weight: 700;
        margin-top: pxToEm(90);
        line-height: 1.1em;
        width: 270px;

        span {
          text-decoration: underline;
        }
      }

      .btn-container {
        max-width: pxToEm(300);
        margin-top: pxToEm(10);
        position: relative;

        .btn-big {
          flex: 0 1 auto;

          .txt {
            font-size: pxToPercent(16);
            font-weight: 700;
            text-align: left;
          }

          .content {
            margin-left: pxToEm(17);
            margin-right: pxToEm(17);
          }

          img {
            width: pxToEm(36);
            height: pxToEm(28);
          }
        }
      }
    }

    .slide-panel {
      max-width: pxToEm(335);
      height: 100%;
      position: absolute;
      right: pxToEm(-340);
      z-index: 500;
      background-color: getColor("greyLight");
      will-change: right;
      transition: 175ms;
      transition-timing-function: ease-in;

      &.on {
        right: 0;
        transition-timing-function: ease-out;
      }

      .arrow {
        padding: pxToEm(25);
        position: absolute;
        cursor: pointer;

        svg {
          width: pxToEm(40);
          height: pxToEm(32);
        }
      }

      .panel-content {
        text-align: left;
        margin: pxToEm(25);

        .title {
          margin-top: pxToEm(90);
          font-weight: 800;

          .txt {
            font-size: pxToPercent(36);
            line-height: 1em;

            span {
              color: getColor("primaryDefault");
              text-decoration: underline;
            }
          }
        }

        .panel-buttons {
          display: flex;
          flex-direction: column;
          margin-top: pxToEm(20);

          .button {
            flex: 1 1 auto;
            height: pxToEm(94);
            display: flex;
            cursor: pointer;

            &:nth-child(odd) {
              background-color: getColor("bgColor");
            }

            &:nth-child(even) {
              background-color: #d8d8d8;
            }

            .icon {
              width: pxToEm(90);
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .btn-txt {
              display: flex;
              align-items: center;
              flex: 1 1 auto;
            }

            .btn-arrow {
              align-self: flex-end;
              margin-bottom: pxToEm(10);
              margin-right: pxToEm(15);

              div {
                display: inline-block;
                padding: pxToEm(2);
                transform: rotate(-45deg);
                border: solid getColor("textColor");
                border-width: 0 pxToEm(2) pxToEm(2) 0;
              }
            }

            &:hover .btn-arrow div {
              border: solid getColor("primaryLight");
              border-width: 0 pxToEm(2) pxToEm(2) 0;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import headerImg from "@/assets/homepage-header.jpg";
import btnBig from "@/components/common/btn-big";
import { strapiAssetUrl } from "@/JS/helpers";

export default {
  name: "mobHeader",
  props: {
    block: Object,
  },
  components: {
    btnBig,
  },
  data() {
    return {
      headerImg,
      panel: false,
    };
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    openPanel() {
      event.stopPropagation();
      if (!this.panel);
      this.panel = true;
    },
    closePanel() {
      event.stopPropagation();
      this.panel = false;
    },
    navTo(path) {
      this.$router.push(path);
      this.closePanel();
    },
  },
};
</script>
