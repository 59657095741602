<template>
  <div>
    <component v-for="(block, index) in blocks"
               :key="`block.__component-${index}`"
               :is="getComponentName(block.__component)"
               :block="block"
    />
  </div>
</template>

<script>
import TrustpilotBlock from "@/components/strapi/TrustpilotBlock";
import CenterTextBlock from "@/components/strapi/CenterTextBlock";
import InfoPageHeaderBlock from "@/components/strapi/InfoPageHeaderBlock";
import UspSSectionBlock from "@/components/strapi/UspSSectionBlock";
import FaqSectionBlock from "@/components/strapi/FaqSectionBlock";
import ContactSectionBlock from "@/components/strapi/ContactSectionBlock";
import TableBlock from "@/components/strapi/TableBlock";
import LargeImageHeaderBlock from "@/components/strapi/LargeImageHeaderBlock";
import ContactUsSectionBlock from "@/components/strapi/ContactUsSectionBlock";
import SimpleHeaderBlock from "@/components/strapi/SimpleHeaderBlock";
import OfferSectionBlock from "@/components/strapi/OfferSectionBlock";
import TwoColumnSectionBlock from "@/components/strapi/TwoColumnSectionBlock";
import BoxedSectionBlock from "@/components/strapi/BoxedSectionBlock";
import PartnersSectionBlock from "@/components/strapi/PartnersSectionBlock";
import VacancySectionBlock from "@/components/strapi/VacancySectionBlock";
import WorkWithUsSectionBlock from "@/components/strapi/WorkWithUsSectionBlock";
import SignupSectionBlock from "@/components/strapi/SignupSectionBlock";
import ColorHeaderBlock from "@/components/strapi/ColorHeaderBlock";
import BlocksSectionBlock from "@/components/strapi/BlocksSectionBlock";
import CalculateMaxLoanSectionBlock from "@/components/strapi/CalculateMaxLoanSectionBlock";
import CalculateLoanSectionBlock from "@/components/strapi/CalculateLoanSectionBlock";
import ComparisonSectionBlock from "@/components/strapi/ComparisonSectionBlock";
import BigCtaSectionBlock from "@/components/strapi/BigCtaSectionBlock";
import HomeHeaderBlock from "@/components/strapi/HomeHeaderBlock";

const _ = require("lodash");

export default {
  name: 'BlockManager',
  props: {
    blocks: Array
  },
  components: {
    TrustpilotBlock,
    CenterTextBlock,
    InfoPageHeaderBlock,
    UspSSectionBlock,
    FaqSectionBlock,
    ContactSectionBlock,
    TableBlock,
    LargeImageHeaderBlock,
    ContactUsSectionBlock,
    SimpleHeaderBlock,
    OfferSectionBlock,
    TwoColumnSectionBlock,
    BoxedSectionBlock,
    PartnersSectionBlock,
    VacancySectionBlock,
    WorkWithUsSectionBlock,
    SignupSectionBlock,
    ColorHeaderBlock,
    BlocksSectionBlock,
    CalculateMaxLoanSectionBlock,
    CalculateLoanSectionBlock,
    ComparisonSectionBlock,
    BigCtaSectionBlock,
    HomeHeaderBlock
  },
  methods: {
    getComponentName: name => {
      let blockName = name.replace('page-components.', '')
      blockName += 'Block';
      blockName = _.camelCase(blockName)
      blockName = blockName.substr(0, 1).toUpperCase() + blockName.substr(1)
      console.log('block: ' + blockName)
      return blockName;
    }
  }
}
</script>
