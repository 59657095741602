var DoorlopendType = function (rate, months) {
  var self = this;

  Object.defineProperty(self, 'rate', {
    enumerable: false,
    configurable: false,
    value: rate
  });

  Object.defineProperty(self, 'months', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: months
  });

  Object.defineProperty(self, 'monthlyFee', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  self.mogelijkheden = self.rate * 100 + "% van het limiet";
  self.maandlast = "€0.00",
    self.effectiveJaarrente = "0%";
  self.nominaleMaandrente = "0%";
  self.theoretischeLooptijd = " maanden";
  self.totaalprijs = "€0.00";

  return self;
};

DoorlopendType.prototype.update = function (amount) {
  this.monthlyFee = (this.rate * amount).toFixed(2);
  var m = amount <= 14999 && this.months == 125 ? this.months + 1 : this.months;
  var t = (this.monthlyFee * this.months).toFixed(2)

  this.maandlast = "€ " + this.monthlyFee;
  // this.effectiveJaarrente = amount <= 14999 ? "4.4%" : "4.6%";
  // this.nominaleMaandrente = amount <= 14999 ? "0.376%" : "0.360%";

  if (amount >= 50000) {
    this.effectiveJaarrente = "4,50%";
    this.nominaleMaandrente = "0,368%";
  } else if (amount >= 25000) {
    this.effectiveJaarrente = "4,50%";
    this.nominaleMaandrente = "0,368%";
  } else if (amount >= 15000) {
    this.effectiveJaarrente = "4,70%";
    this.nominaleMaandrente = "0,383%";
  } else {
    this.effectiveJaarrente = "6,10%";
    this.nominaleMaandrente = "0,495%";
  }

  this.theoretischeLooptijd = m + " maanden";
  this.totaalprijs = "€" + t;
}


var PersoonlijkeType = function (months) {
  var self = this;

  Object.defineProperty(self, 'months', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: months
  });

  Object.defineProperty(self, 'monthlyFee', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  Object.defineProperty(self, 'anualInterest', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  Object.defineProperty(self, 'monthlyInterest', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  self.mogelijkheden = this.months + " maanden";
  self.maandlast = "€0.00";
  self.effectiveJaarrente = "0%";
  self.nominaleMaandrente = "0%";
  self.totaalprijs = "€0.00";

  return this;
}

PersoonlijkeType.prototype.update = function (amount) {
  var self = this;
  // if (amount <= 14999) {
  //     self.anualInterest = 0.058;
  //     self.monthlyInterest = 0.00470;
  // } else if (amount >= 15000 && amount <= 25000) {
  //     self.anualInterest = 0.047;
  //     self.monthlyInterest = 0.00383;
  // } else if (amount > 25000) {
  //     self.anualInterest = 0.044;
  //     self.monthlyInterest = 0.00367;
  // }

  // if (amount >= 50000) {
  //   this.anualInterest = 0.035;
  //   this.monthlyInterest = 0.00287;
  // } else if (amount >= 25000) {
  //   this.anualInterest = 0.037;
  //   this.monthlyInterest = 0.00303;
  // } else if (amount >= 15000) {
  //   this.anualInterest = 0.0420;
  //   this.monthlyInterest = 0.00343;
  // } else {
  //   this.anualInterest = 0.059;
  //   this.monthlyInterest = 0.00482;
  // }

  if (amount >= 50000) {
    this.anualInterest = 0.034;
    this.monthlyInterest = 0.00279;
  } else if (amount >= 25000) {
    this.anualInterest = 0.036;
    this.monthlyInterest = 0.00295;
  } else if (amount >= 15000) {
    this.anualInterest = 0.0410;
    this.monthlyInterest = 0.00335;
  } else if (amount >= 10000) {
    this.anualInterest = 0.0580;
    this.monthlyInterest = 0.00471;
  } else {
    this.anualInterest = 0.064;
    this.monthlyInterest = 0.00518;
  }

  //R / (1 - ((1 + R) ^ -mogelijkheden)) * X
  self.monthlyFee = self.monthlyInterest / (1 - Math.pow((1 + self.monthlyInterest), -self.months)) * amount;

  self.maandlast = "€" + self.monthlyFee.toFixed(2);
  self.effectiveJaarrente = (self.anualInterest * 100).toFixed(2) + '%';
  self.nominaleMaandrente = (self.monthlyInterest * 100).toFixed(2) + '%';
  self.totaalprijs = "€" + (self.monthlyFee * self.months).toFixed(2);
}

export {DoorlopendType, PersoonlijkeType}
