<template>
  <div
    class="table"
    :class="{ doorlopend: $store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND }"
  >
    <div class="col">
      <div class="tr"></div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      ></div>
      <div class="tr gap"></div>
    </div>

    <div
      class="col"
      v-for="(k, i) in loanInfo[$store.state._loanRequest.loanTypeTable].title"
      :key="i"
    >
      <div class="tr">{{ k }}</div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      >{{ getObjectKeyValue(p, i) }}</div>
      <div class="tr gap"></div>
    </div>

    <div class="col">
      <div class="tr"></div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      ></div>
      <div class="tr gap"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.table {
  display: grid;
  grid-template-columns: pxToEm(35) 1fr 1fr 1fr 1fr 1fr pxToEm(35);
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }

  &.doorlopend {
    grid-template-columns: pxToEm(35) 1fr 1fr 1fr 1fr 1fr 1fr pxToEm(35);

    .col {
      .tr {
        &:nth-child(5) {
          border: none;
        }
      }
    }
  }

  .col {
    .tr {
      box-sizing: border-box;
      display: grid;
      align-items: center;
      height: pxToEm(60);
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      color: #3a3a36;

      &:first-child {
        background-color: getColor("grayDkColor");
        color: #ffffff;
        font-weight: 700;
        height: pxToEm(100);
        border: none;
      }

      &:nth-child(2),
      &:last-child {
        border: none;
        height: pxToEm(25);
      }

      &:nth-child(7) {
        border: none;
      }
    }

    &:first-child,
    &:last-child {
      width: pxToEm(35);
      .tr {
        border: none;
      }
    }
  }
}
</style>

<script>
import { DoorlopendType, PersoonlijkeType } from "@/data/loanTypes";

export default {
  name: "loanTypeTable",
  data() {
    return {
      loanInfo: [
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Totaalprijs"],
          data: [new PersoonlijkeType(180), new PersoonlijkeType(120), new PersoonlijkeType(96), new PersoonlijkeType(60), new PersoonlijkeType(36)]
        },
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Theoretische looptijd", "Totaalprijs"],
          data: [new DoorlopendType(0.01, 125), new DoorlopendType(0.015, 77), new DoorlopendType(0.02, 56)]
        }
      ]
    };
  },
  created() {
    //this.calcMaandlast($store.state._loanRequest.approvedLoanAmount);

    //FIX FOR NaN VALUE WHEN COMMING FROM CALCULATOR B    
    this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.loanAmount : $store.state._loanRequest.approvedLoanAmount);
    //END FIX
  },
  methods: {
    calcMaandlast(val) {
      var x;
      var result;
      for (var i = 0; i < this.loanInfo[$store.state._loanRequest.loanTypeTable].data.length; i++) {
        this.loanInfo[$store.state._loanRequest.loanTypeTable].data[i].update(val);
      }
    },
    getObjectKeyValue(obj, idx) {
      return obj[Object.keys(obj)[idx]];
    }
  },
  watch: {
    "$store.state._loanRequest.approvedLoanAmount": function(newVal, oldVal) {
      this.calcMaandlast(newVal);
    },
    "$store.state._loanRequest.loanTypeTable": function(newVal, oldVal) {
      //this.calcMaandlast($store.state._loanRequest.approvedLoanAmount);
      //FIX FOR NaN VALUE WHEN COMMING FROM CALCULATOR B
      this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.loanAmount : $store.state._loanRequest.approvedLoanAmount);    
      //END FIX
    }
  }
};
</script>
