<template>
  <div class="loan-type-table">
    <div class="white-container" v-for="(d, n) in loanInfo[$store.state._loanRequest.loanTypeTable].data" :key="n">
      <div class="row" v-for="(t, i) in loanInfo[$store.state._loanRequest.loanTypeTable].title" :key="i">
        <div class="description" >
          <div class="txt">
            {{ t }}
          </div>
        </div>

        <div class="gap"></div>

        <div class="value">
          <div class="txt">
            {{ getObjectKeyValue(loanInfo[$store.state._loanRequest.loanTypeTable].data[n], i) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .white-container {
    margin-bottom: pxToEm(25);
    padding: pxToEm(25) pxToEm(20) 0;
    color: getColor('textColor');
    background-color: #ffffff;

    .row {
      height: pxToEm(70);
      display: grid;
      grid-template-columns: pxToEm(120) 0.35fr 1fr;
      align-items: center;
      border-bottom: 1px solid getColor('grayLtColor');

      .description {
        .txt {
          font-weight: 700;
        }
      }

      .value {
        .txt {
          font-weight: 600;
        }
      }

      &:first-child {
        height: auto;
        display: block;
        text-align: center;

        & .description {
          .txt {
            font-weight: 400;
          }
        }

        & .value {
          margin: pxToEm(10) 0 pxToEm(25);

          .txt {
            font-weight: 700;
            color: getColor('greenDefault');
          }
        }
      }

      &:last-child {
        border: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>

<script>
  import { DoorlopendType, PersoonlijkeType } from "@/data/loanTypes";

  export default {
    name: "loanTypeTable",
    data() {
      return {
        loanInfo: [
          {
            title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Totaalprijs"],
            data: [new PersoonlijkeType(180), new PersoonlijkeType(120), new PersoonlijkeType(96), new PersoonlijkeType(60), new PersoonlijkeType(36)]
          },
          {
            title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Theoretische looptijd", "Totaalprijs"],
            data: [new DoorlopendType(0.01, 125), new DoorlopendType(0.015, 77), new DoorlopendType(0.02, 56)]
          }
        ]
      };
    },
    created() {
      //this.calcMaandlast($store.state._loanRequest.approvedLoanAmount);
      console.log('calculating this shizzle!', $store.state._loanRequest.approvedLoanAmount);
      console.log('the other', $store.state._loanRequest.loanAmount);
      this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.loanAmount : $store.state._loanRequest.approvedLoanAmount);

    },
    methods: {
      calcMaandlast(val) {
        var x;
        var result;
        for (var i = 0; i < this.loanInfo[$store.state._loanRequest.loanTypeTable].data.length; i++) {
          this.loanInfo[$store.state._loanRequest.loanTypeTable].data[i].update(val);
        }
      },
      getObjectKeyValue(obj, idx) {
        return obj[Object.keys(obj)[idx]];
      }
    },
    watch: {
      "$store.state._loanRequest.approvedLoanAmount": function(newVal, oldVal) {
        this.calcMaandlast(newVal);
      },
      "$store.state._loanRequest.loanTypeTable": function(newVal, oldVal) {
        this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.loanAmount : $store.state._loanRequest.approvedLoanAmount);
      }
    },
  };
</script>
