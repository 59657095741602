<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="form" v-if="divNum == 2">
          <div :class="{ formcheck: form0.state == false }" v-if="formSent == false">
            <div class="form-title">
              <div class="txt">{{block.Title}}</div>
            </div>

            <div id="form-error"></div>

            <div class="sep-50"></div>

            <div class="error" v-if="form0.state == false">Er zijn enkele velden niet correct ingevuld.</div>

            <div class="content">
              <div class="col-11 text-input">
                <div class="txt-label">Voorletter(s)</div>
                <input-text v-model="form0.fields.firstName"></input-text>
              </div>
              <div class="col-11 text-input">
                <div class="txt-label">Tussenvoegsel</div>
                <input-text v-model="form0.fields.middleName"></input-text>
              </div>
              <div class="col-11 text-input">
                <div class="txt-label">Achternaam</div>
                <input-text v-model="form0.fields.lastName"></input-text>
              </div>

              <div class="col-11 radio-input">
                <div class="txt-label">Geslacht</div>
                  <input-radio v-model="form0.fields.gender">
                    <span slot="option1">Man</span>
                    <span slot="option2">Vrouw</span>
                  </input-radio>
              </div>

              <div class="col-11 text-input">
                <div class="txt-label">Straat</div>
                <input-text v-model="form0.fields.streetName"></input-text>
              </div>
              <div class="col-11 text-input">
                <div class="txt-label">Huisnummer</div>
                <input-text v-model="form0.fields.houseNumber"></input-text>
              </div>
              <div class="col-11 text-input">
                <div class="txt-label">Toevoeging</div>
                <input-text v-model="form0.fields.affix"></input-text>
              </div>

              <div class="col-11 text-input">
                <div class="txt-label">Postcode</div>
                <input-text v-model="form0.fields.postCode"></input-text>
              </div>
              <div class="col-11 text-input">
                <div class="txt-label">Plaats</div>
                <input-text v-model="form0.fields.city"></input-text>
              </div>

              <div class="col-11">
                <div class="txt-label">Vaste telefoonnummer (Optioneel)</div>
                <input-text v-model="form0.fields.fixedPhoneNumber"></input-text>
              </div>
              <div class="col-11 text-input">
                <div class="txt-label">Mobiele telefoonnummer</div>
                <input-text v-model="form0.fields.mobilePhoneNumber"></input-text>
              </div>

              <div class="col-11">
                <div class="txt-label">E-mail</div>
                <input-text v-model="form0.fields.emailAddress"></input-text>
              </div>

              <div class="col-11 textarea-input">
                <div class="txt-label">Opmerkingen (Optioneel)</div>
                <textarea v-model="form0.fields.comments"></textarea>
              </div>
            </div>

            <btn-big class="primary form-btn" :height="86" :click="submitForm">
              <div class="content">
                <div class="txt">Ik stuur u de gegevens van deze klant</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>
          </div>

          <form-message v-else-if="formSent == true" :option="'ABOUT'"></form-message>

        </div>
  </div>
</template>

<style lang="less" scoped>
  @border-width: 2px;
  @gridRowGap: 2em;
  @gridColGap: 1.25em;
  @gridColHalfGap: @gridColGap * 0.5;

  .area {
    min-height: pxToEm(755);
    background-color: getColor("greyLight");

    &.white {
      background-color: #fff;
    }

    .form {
          //margin: pxToEm(-43) 0 pxToEm(25);
          padding: pxToEm(60) pxToEm(25);
          text-align: center;
          background-color: getColor("bgColor");
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

          svg {
            margin-top: pxToEm(25);
            width: pxToEm(55);
          }

          .form-title {
            margin-top: pxToEm(30);

            .txt {
              font-size: pxToEm(40);
              font-weight: 300;
            }
          }

          .error {
            width: 100%;
            max-width: pxToEm(400);
            margin: 0 auto pxToEm(50);
            color: getColor("redColor");
            font-style: italic;
            text-align: left;
          }

          .content {
            width: 100%;
            max-width: pxToEm(400);
            margin: 0 auto;
            display: grid;
            grid-row-gap: @gridRowGap;
            text-align: left;

            .txt-label {
              margin-bottom: pxToEm(10);
              font-size: pxToPercent(16);
              font-weight: 300;
            }

            textarea {
              text-align: left;
              font-size: pxToPercent(16);
              background-color: getColor("whiteColor");
              border: @border-width solid getColor("grayLtColor");
              resize: none;
            }

            .col-11 {
              display: grid;
              grid-template-rows: 1fr pxToEm(52);

              &.textarea-input {
                grid-template-rows: 1fr pxToEm(210);
              }
            }
          }

          .form-btn {
            max-width: pxToEm(400);
            margin: pxToEm(50) auto 0;

            .content {
              margin: 0 pxToEm(20);
              margin-right: pxToEm(30);
              display: flex;
              flex-flow: row;

              .txt {
                margin-right: pxToEm(20);
                font-size: pxToPercent(16);
                font-weight: 700;
              }

              img {
                width: pxToEm(36);
                height: pxToEm(28);
              }
            }
          }

          .white-button {
            bottom: pxToEm(30);
          }

          .form-message {
            margin: pxToEm(50) pxToEm(25);
          }
        }
  }

</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import inputDropdown from "@/components/common/input-dropdown";
  import inputRadio from "@/components/common/input-radio";
  import inputText from "@/components/common/input-text";
  import formMessage from "@/components/common/form-message";
  import { _form0 } from "@/data/aboutData.js";
  import { goToAnchor, checkTab } from "@/JS/helpers";
  import { dataToMail, mails } from "@/JS/mail";

  export default {
    name: "MobileSignupSectionBlock",
    components: {
      btnBig,
      inputDropdown,
      inputRadio,
      inputText,
      formMessage
    },
    props: {
      block: Object
    },
    data() {
      return {
        dropdown1value: null,
        $div1: null,
        divNum: 2,
        workActive: false,
        form0: {
          fields: new _form0(),
          state: undefined
        },
        formSent: false,
      };
    },
    mounted() {
      this.$div1 = document.querySelector(".form");
    },
    methods: {
      submitForm() {
        this.form0.state = checkTab(this.form0.fields);
        const verkoperId = this.$route.query.verkoperId
        const subagentId = this.$route.query.subagentId
        if (this.form0.state == false) {
          goToAnchor("#form-error");
        }
        else {
          dataToMail({
            mailTo: mails.info,
            mailSubject: "Over Leenattent",
            mailData: this.form0.fields
          }, verkoperId, subagentId)
            .then(res => {
              //CODE FOR MAIL SENT /////////////
              this.form0.fields = new _form0();
              this.form0.state = undefined;
              this.formSent = true;
              goToAnchor("#form-top");
              // console.log(res);
            })
            .catch(rej => {
              //CODE FOR MAIL NOT SENT /////////////
              this.formSent = false;
              // console.log(rej);
            });
        }
      }
    }
  };
</script>
