import { render, staticRenderFns } from "./UspSSectionBlock.vue?vue&type=template&id=4143a025&scoped=true&"
import script from "./UspSSectionBlock.vue?vue&type=script&lang=js&"
export * from "./UspSSectionBlock.vue?vue&type=script&lang=js&"
import style0 from "./UspSSectionBlock.vue?vue&type=style&index=0&id=4143a025&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4143a025",
  null
  
)

export default component.exports