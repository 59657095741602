<template>
  <div class="container-b">
    <div class="slider-container" v-if="$store.state.storedData && $store.state._loanRequest.maxLoanAmount > 0">
      <template v-if="$store.state._loanRequest.selectedFunnel == $store.state.funnel.A">
        <div class="white-container">
          <template v-if="$store.state._loanRequest.loanAmount > $store.state._loanRequest.maxLoanAmount">
            <div class="txt">Helaas, u kunt geen&nbsp;
              <span class="bolder color-primary-default">&euro;&nbsp;{{
                  $store.state._loanRequest.loanAmount
                }}</span>
              &nbsp;verantwoord lenen via Leenattent. U kunt maximaal&nbsp;
              <span class="bolder green">&euro;&nbsp;{{ $store.state._loanRequest.maxLoanAmount }}</span>&nbsp;.
            </div>
          </template>

          <template v-else>
            <div class="txt">Op basis van uw gegevens kunt u het volgende bedrag verantwoord lenen:</div>
            <div class="txt bolder green">&euro;&nbsp;{{ $store.state._loanRequest.maxLoanAmount }}</div>
            <div>Het werkelijk verantwoord te lenen bedrag kan afwijken door uw persoonlijke situatie. U kunt aan
              deze berekening geen rechten ontlenen
            </div>
          </template>
        </div>

        <div class="grey-container">
          <div class="slider">
            <div class="txt">Gewenste leenbedrag</div>
            <input-slider
              class="primary"
              :css="$store.state.defaults.sliderCSSMobile"
              :maxVal="$store.getters.getMaxLoanAmount"
              v-model="$store.state._loanRequest.approvedLoanAmount"
            ></input-slider>
            <input-num v-model="$store.state._loanRequest.approvedLoanAmount"
                       :maxVal="$store.getters.getMaxLoanAmount"></input-num>
          </div>
        </div>
      </template>

      <template v-else-if="$store.state._loanRequest.selectedFunnel == $store.state.funnel.B">
        <div class="white-container">
          <div class="txt"
               v-if="$store.state._loanRequest.newLoan.interestSaving > 0 && $store.state._loanRequest.newLoan.total <= $store.state._loanRequest.maxLoanAmount">
            U kunt&nbsp;
            <span class="bolder green">&euro;&nbsp;{{ $store.state._loanRequest.newLoan.interestSaving }}</span>&nbsp;
            besparen via Leenattent.
          </div>
          <div class="txt" v-else>
            <div>Helaas, u kunt geen lening oversluiten via Leenattent. Neem voor meer informatie contact met ons
              op.
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="slider-container"
         v-else-if="$store.state.storedData && $store.state._loanRequest.maxLoanAmount <= 0">
      <div class="white-container" v-if="$store.state._loanRequest.selectedFunnel == $store.state.funnel.A">
        <div class="txt">
          Helaas, u kunt geen&nbsp;
          <span class="bolder color-primary-default">&euro;&nbsp;{{ $store.state._loanRequest.loanAmount }}</span>
          &nbsp; verantwoord lenen via Leenattent.
        </div>
      </div>
      <div class="white-container" v-else-if="$store.state._loanRequest.selectedFunnel == $store.state.funnel.B">
        <div class="txt">Helaas, u kunt geen lening oversluiten via Leenattent. Neem voor meer informatie contact
          met ons op.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>

</style>

<script>
import inputSlider from "@/components/common/input-slider";
import inputNum from "@/components/common/input-num";
import inputDropdown from "@/components/common/input-dropdown";

export default {
  name: "MobileOfferSectionHead",
  components: {
    inputSlider,
    inputNum,
    inputDropdown
  }
};
</script>
