<template>
  <div>
    <div class="area">
      <div class="layout">
        <div class="section">
          <div class="title">{{block.Title}}</div>
          <div class="dash"></div>
          <div class="pic" :style="`background-image: url(${strapiAssetUrl(block.Image.data.attributes.url)});`"></div>
        </div>
      </div>
    </div>

    <calculator class="area white"></calculator>
  </div>
</template>

<style lang="less" scoped>
.area {
    background-color: getColor("greyLight");
    padding: 0 15px;
    box-sizing: border-box;
    &.white {
      background-color: #fff;
    }
  
    .font-weight-bold {
      font-weight: 700;
    }
  
    .layout {
      align-items: center;
      max-width: pxToEm(965);
  
      img {
        max-width: 100%;
      }
  
      .section {
        margin: pxToEm(50);
        width: pxToEm(1160);
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: center;
  
        a.no-underline {
          text-decoration: none;
        }
  
        .info {
          text-align: left;
        }
  
        .title {
          font-size: pxToPercent(70);
          font-weight: 800;
          line-height: 1em;
        }
  
        .dash {
          width: pxToEm(123);
          height: pxToEm(11);
          background-color: getColor("primaryLight");
          margin-top: pxToEm(20);
        }
  
        .pic {
          width: 100%;
          height: pxToEm(350);
          margin-top: pxToEm(40);
          margin-bottom: pxToEm(50);
          background-position: center;
          background-size: cover;
        }
  
        .gap {
          height: pxToEm(100);
        }
      }
    }
  }

  @media screen {
    @media (max-width: 767px) {
      .area {
        .layout {
          .section {
            .title {
              font-size: pxToEm(48);
            }
          }
        }          
      }
      .calc-area {
        padding-top: 5em;
      }
    }
    @media (min-width: 769px){
      .area{
        &.white{
          &.mobile{
            display: none;
          }
        }
      }
    }
    @media (max-width: 769px){
      .area{
        &.white{
          &.desktop{
            display: none;
          }
        }
      }
    }
  }
</style>

<script>
import { strapiAssetUrl } from "@/JS/helpers";
import calculator from "@/components/desktop/calculators/A/calculator-form.vue";

export default {
  name: 'InfoPageHeaderBlock',
  components: {
    calculator
  },
  props: {
    block: Object
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  }
}
</script>
