<template>
  <div>
    <div class="layout">
      <div class="section">
      <div class="header">
              <div class="title">
                <div class="txt">
                  Wat zeggen
                  <br>ze over ons?
                </div>
              </div>
            </div>
        <div class="top">
          <div ref="trustpilotDesk" class="trustpilot-widget d-mobile-none"  data-locale="nl-NL" data-template-id="539ad60defb9600b94d7df2c" data-businessunit-id="5a0c4c430000ff0005b089a3" data-style-height="500px" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="nl">

            <a href="https://nl.trustpilot.com/review/leenattent.nl" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <div ref="trustpilotMob" class="trustpilot-widget d-desktop-none"  data-locale="nl-NL" data-template-id="539ad60defb9600b94d7df2c" data-businessunit-id="5a0c4c430000ff0005b089a3" data-style-height="350px" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="nl">

            <a href="https://nl.trustpilot.com/review/leenattent.nl" target="_blank" rel="noopener">Trustpilot</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("bgColor");

  .layout {
    align-items: center;

    .section {
      width: pxToEm(965);
      max-width: 100%;
      margin: 0 auto;
      margin-top: pxtoEm(0);
      //max-width: pxToEm(1160);
      //display: flex;
      //flex-direction: column;
      //flex: 1 1 auto;

      .gap {
        height: pxToEm(100);
      }

      .top {
        margin: pxToEm(70);
        //margin-top: pxToEm(10);
        //text-align: left;

      }
      .header {
            margin-top: pxToEm(50);
            margin-bottom: pxToEm(30);
            .title {

              .txt {
                font-size: pxToPercent(80);
                line-height: 1em;
                font-weight: 300;
              }
            }
          }
    }
  }
}
  .d-mobile-none{
    display:block
  }
  .d-desktop-none{
    display:none
  }
  @media only screen and (max-width:767px){
    .d-mobile-none{
      display:none;
    }
    .d-desktop-none{
      display:block;
    }
    .area {
      .layout {
        .section {
          .top {
            margin: pxToEm(20);
          }
          .header {
            margin-top: pxToEm(30);
            margin-bottom: pxToEm(30);
            .title {

              .txt {
                font-size: pxToPercent(40);
                line-height: 1em;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
</style>

<script>

export default {
  name: 'trustpilot',
  props: {
    templateId: {
      type: String,
      default: "539ad60defb9600b94d7df2c"
    },
    white: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    window.Trustpilot.loadFromElement(this.$refs.trustpilotDesk, true)
    window.Trustpilot.loadFromElement(this.$refs.trustpilotMob, true)
  },
}
</script>
