<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="team">
          <div class="pic" :style="{ 'background-image': 'url(' + strapiAssetUrl(block.image.data.attributes.url) + ')' }"></div>
          <div class="content">
            <div class="title">{{block.Title}}</div>

            <div class="paragraphs">
              <div class="paragraph" v-for="paragraph in block.Content">
                <div class="p-subtitle">{{paragraph.Title}}</div>
                <div class="p-content" v-html="markdownToHTML(paragraph.text)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  min-height: 1200px;
  display: flex;
  align-items: stretch;

  &.white {
    background-color: #fff;
  }

  .layout {
    justify-content: center;
    align-items: stretch;
    flex: 1 1 auto;
    max-width: 1312px;

    .section {
      max-width: 1200px;
      margin: pxToEm(50);
      text-align: left;

      .team {
        height: auto;

        .pic {
          height: pxToEm(480);
          background-size: cover;
          background-repeat: no-repeat;
          background-position-y: 75%;
        }

        .content {
          background-color: getColor("whiteColor");
          height: auto;
          padding: 0 pxToEm(115);
          padding-top: pxToEm(60);
          padding-bottom: pxToEm(100);
          margin-bottom: pxToEm(50);

          .title {
            font-size: pxToEm(60);
            font-weight: 700;
            text-decoration: underline;
          }

          .paragraphs {
            .paragraph {
              margin-top: pxToEm(60);

              .p-subtitle {
                font-size: pxToEm(36);
                font-weight: 300;
              }

              .p-content {
                font-size: pxToEm(18);
                line-height: 2.1em;
                margin-top: pxToEm(20);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .text {
    max-width: 100% !important;
    padding: 0 pxToEm(115);
  }
}
</style>

<script>
  import {marked} from 'marked'
  import {strapiAssetUrl} from "@/JS/helpers";

  export default {
    name: "DesktopBoxedSectionBlock",
    methods: {
      markdownToHTML: function(markdown) {
        return marked(markdown);
      },
      strapiAssetUrl: function (path) {
        return strapiAssetUrl(path)
      }
    },
    props: {
      block: Object
    }
  };
</script>
