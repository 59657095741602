import Vue from 'vue'
import Vuex from 'vuex'
import { _person, _loanRequest, _prevLoan, _currentLoans, _newLoan } from './data/data'
import { maxLoanCalc } from "@/data/maxLoanCalc";
import { setCookie, getCookie, startAnalytics, stopAnalytics, startMarketing, stopMarketing } from "@/JS/cookies";

Vue.use(Vuex)

window.$store = new Vuex.Store({
    state: {
        Device: {
            DESKTOP: 0,
            TABLET: 1,
            MOBILE: 2
        },
        deviceState: null,
        lastDeviceState: null,
        defaults: {},
        modal: false,
        storedData: false,
        berekenFormValid: false,
        berekenFormData: {},
        formsOfferte: [],
        funnel: {
            A: 0,
            B: 1
        },
        tabErrors: {},
        formSent: false,
        funnelOptions: [
            "Lening(en) oversluiten", // Funnel B ?
            "Lening voor een verbouwing",
            "Lening voor een auto",
            "Lening voor financiele ruimte",
            "Lening voor onverwachte uitgaven",
            "Lening voor een nieuwe boot",
            "Lening voor een nieuwe camper",
            "Lening voor een nieuwe caravan",
            "Lening voor een nieuwe motor",
            "Lening voor een nieuwe keuken",
            "Lening voor nieuwe zonnepanelen",
            "Lening voor restschuld hypotheek",
            "Lening voor uitkopen ex-partner",
            "Lening voor vakantie",
            "Lening voor studie",
            "Overige, een andere leningsdoel"
        ],
        funnelBOption: 0,
        incomeSourceDropdown: [
            "Vast dienstverband",
            "Tijdelijk dienstverband",
            "Bij uitzendbureau fase A",
            "Bij uitzendbureau fase B",
            "Bij uitzendbureau fase C",
            "WAO uitkering",
            "WIA (IVA) uitkering",
            "WIA (WGA) uitkering",
            "ZZP langer dan 3 jr.",
            "ZZP korter dan 3 jr.",
            "DGA langer dan 3 jr.",
            "DGA korter dan 3 jr.",
            "Pensioen",
            "WW uitkering",
            "Bijstandsuitkering",
            "Geen dienstverband"
        ],
        livingStateDropdown: [
            "Koop",
            "Huur",
            "Inwonend"
        ],
        loanType: {
            PERSOONLIJKE: 0,
            DOORLOPEND: 1,
            ADVIES: 2
        },
        loanTypeOptions: [
            "Persoonlijke lening",
            "Doorlopend krediet",
            "Perspectieflening",
            "Weet ik nog niet. Graag advies."
        ],
        loansDropdown: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        _person: new _person(),
        _loanRequest: new _loanRequest(),
        _prevLoan: function() {
            return new _prevLoan();
        },
        prevLoanTemplate: function(opt) {
            var o;
            if (opt == undefined)
                o = true;
            else
                o = opt;
            return new _prevLoan(o);
        },
        cookies: {
            _names: {
                analytics: "cnAnalytics",
                marketing: "cnMarketing"
            },
            analytics: null,
            marketing: null
        }
    },
    mutations: {
        changeTabErrors(state, val) {
            state.tabErrors = val;
        },
        changeDevice(state, val) {
            state.deviceState = val;
        },
        setDefaults(state, val) {
            state.defaults = val;
        },
        createPerson(state, val) {
            state._person = new _person();
        },
        createLoanRequest(state, val) {
            state._loanRequest = new _loanRequest();
        },
        createPrevLoan(state, val) {
            state._prevLoan = new _prevLoan();
        },
        resetForms(state, val) {
            state.storedData = false;
            state._person = new _person();
            state._loanRequest = new _loanRequest();
            Vue.set(state._loanRequest, 'currentLoans', new _currentLoans());
            Vue.set(state._loanRequest, 'newLoan', new _newLoan());
            state._prevLoan = new _prevLoan();
        },
        calcMaxLoanAmount(state, val) {
            state._loanRequest.requestedLoanAmount = state._loanRequest.loanAmount;
            var max = maxLoanCalc(state._person, state._loanRequest);
            // console.log("max approved: ",max);
            if (max < state._loanRequest.loanAmount.ui.minVal) max = 0
            else if (max > state._loanRequest.loanAmount.ui.maxVal) max = state._loanRequest.loanAmount.ui.maxVal

            state._loanRequest.maxLoanAmount = max;

            //   //0 - APROVED
            //   //1 - PARTIALLY REJECTED
            //   //2 - REJECTED
            if (state._loanRequest.maxLoanAmount <= 0) state._loanRequest.calcResult = 2;
            else if (state._loanRequest.maxLoanAmount < state._loanRequest.loanAmount && state._loanRequest.loanAmount > 0) state._loanRequest.calcResult = 1;
            else state._loanRequest.calcResult = 0;

            if (state._loanRequest.maxLoanAmount < state._loanRequest.loanAmount)
                state._loanRequest.approvedLoanAmount = max;
            else
                state._loanRequest.approvedLoanAmount = state._loanRequest.loanAmount;

        },
        loadCookies: function(state, initial) {
            var cookies = state.cookies;
            for (var cn in cookies._names) {
                cookies[cn] = getCookie(cookies._names[cn]);
                if (cookies[cn] != null)
                    cookies[cn] = cookies[cn] == 'true' ? true : false;
            }

            if (initial) {
                if (cookies.analytics)
                    startAnalytics();
                else
                    stopAnalytics();

                if (cookies.marketing)
                    startMarketing();
                else
                    stopMarketing();
            }
        },
        acceptCookies: function(state) {
            var cookies = state.cookies;
            var timeoutDays = 60;
            for (var cn in cookies._names) {
                setCookie(cookies._names[cn], cookies[cn], timeoutDays);
            }
            $store.commit('loadCookies', true);
        }
    },
    actions: {

    },
    getters: {
        getMaxLoanAmount: state => {
            if (state._loanRequest.maxLoanAmount > 0)
                return state._loanRequest.maxLoanAmount
            else
                return state._loanRequest.loanAmount.ui.maxVal;
        }
    }
});
window.$store.commit('loadCookies', true);

export default window.$store;