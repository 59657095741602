<template>
  <div>
    <CenterTextBlockDefault v-if=" ! block.ReadMore || block.ReadMore.length == 0 " :block="block" />
    <CenterTextBlockReadMore v-if="block.ReadMore && block.ReadMore.length > 0" :block="block" />
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import CenterTextBlockReadMore from './CenterText/CenterTextBlockReadMore'
import CenterTextBlockDefault from './CenterText/CenterTextBlockDefault'

export default {
  name: 'CenterTextBlock',
  components: {
    CenterTextBlockReadMore,
    CenterTextBlockDefault
  },
  props: {
    block: Object
  },
}
</script>
