<template>
  <div
    class="input-dropdown input"
    :class="{ disabled: ui.disabled == true ? true : false, initialized: ui.init == true ? true : false, invalid: ui.valid ? false : true }"
    @click.prevent.stop="toggle"
  >
    <div class="selection-container">
      <div class="selection" :class="{ selected: didSelection }">
        <div class="txt">{{ selectedTxt }}</div>
      </div>
      <div class="arrow"></div>
    </div>
    <div
      class="scroll-container"
      :style="{ height: scrollContainerHeight + 'em' }"
      v-show="!ui.disabled && isOpen"
    >
      <div class="options-container">
        <div
          class="option"
          v-for="(val,idx) in options"
          :key="idx"
          @click="set(val,idx)"
        >{{ val }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @border-width: pxToEm(2);

  .input-dropdown {
    z-index: 200;
    position: relative;
    color: getColor("grayLtColor");
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;

    .selection-container {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-align: left;
      background-color: getColor("whiteColor");
      border: @border-width solid getColor("grayLtColor");
      box-sizing: border-box;

      .selection {
        flex: 1 1 auto;
        padding-left: pxToEm(20);
        padding-right: pxToEm(30);

        &.selected {
          color: getColor("textColor");
        }
      }

      .arrow {
        position: relative;
        border: solid getColor("textColor");
        border-width: 0 pxToEm(2) pxToEm(2) 0;
        padding: pxToEm(4);
        right: pxToEm(15);
        transform: rotate(45deg);

        &.up {
          transform: rotate(-135deg);
        }
      }

      &.shadow {
        box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.3);
      }
    }

    .scroll-container {
      position: absolute;
      top: 100%;
      background-color: getColor("whiteColor");
      border: @border-width solid getColor("grayLtColor");
      border-top: none;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;

      .options-container {
        .option {
          display: flex;
          align-items: center;
          text-align: left;
          padding: 0.9em 1.25em;
          border-bottom: pxToEm(1) solid getColor("grayLtColor");

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            color: getColor("textColor");
          }
        }
      }
    }

    &.invalid .selection-container {
      .formcheck & {
        border: @border-width solid getColor("redColor");
      }
    }

    &.disabled {
      cursor: default;

      .selection-container {
        background-color: getColor("disabledBg");

        .selection {
          color: getColor("disabledText");
        }

        .arrow {
          border: solid getColor("disabledText");
          border-width: 0 pxToEm(2) pxToEm(2) 0;
        }
      }
    }
  }
</style>

<script>
  export default {
    props: {
      disabled: null,
      init: null,
      valid: null,
      options: {},
      placeholder: {
        type: String,
        default: null
      },
      value: null
    },
    data: function() {
      return {
        isOpen: false,
        selectedTxt: null,
        didRunOnce: false,
        didSelection: false,
        container: null,
        containerBCR: null,
        optionBCR: null,
        arrow: null,
        //optionHeightMult: 0.95,
        scrollContainerHeight: 0,
        optionBorderWidth: 1,
        minNumOptionSlots: 1,
        ui: {
          disabled: false,
          init: false,
          valid: null
        }
      };
    },
    created(){
      if(this.value != null){
        if(this.value.ui)
          this.value.ui.visible = true;
      }
      Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
    },
    mounted: function() {
      if (this.ui.init == true) {
        this.selectedTxt = this.options[this.value];
        this.didSelection = true;
      } else {
        this.selectedTxt = this.ui.placeholder;
      }
      this.container = this.$el.querySelector(".selection-container");
      this.option = this.$el.querySelector(".option");
      this.optionFontSize = parseFloat(window.getComputedStyle(this.option).fontSize);
      this.arrow = this.$el.querySelector(".arrow");
      window.addEventListener("click", this.close);
    },
    beforeDestroy: function() {
      window.removeEventListener("click", this.close);
      if(this.value != null){
        if(this.value.ui)
          this.value.ui.visible = false;
      }
    },
    computed: {
      OptionHeight: function() {
        if (this.optionBCR !== null) {
          return this.optionBCR.height;
        }
      },
      OptionPlusBorderHeight: function() {
        if (this.optionBCR !== null) {
          return this.OptionHeight + this.optionBorderWidth;
        }
      }
    },
    watch:{
      value:function(newVal,oldVal){
        if (newVal != null && newVal)
          Object.assignSet(this.ui, this.value.ui);
      }
    },
    methods: {
      pxToEm: function(val) {
        return val / $store.state.defaults.defaultFontSize;
      },
      setScrollContainerHeight: function() {
        if (this.container !== null && this.didRunOnce == false) {
          this.didRunOnce = true;
          this.containerBCR = this.container.getBoundingClientRect();
          this.optionBCR = this.option.getBoundingClientRect();
          var availableHeight = window.innerHeight - this.containerBCR.top - this.containerBCR.height; // Using fixed window.innerHeight here prevents mobile and tablet to work properly because they are inside a scrolable container that is shorter than the window total haeight.
          //var desktopZoom = window.devicePixelRatio;
          var numOptionSlots = Math.floor(availableHeight / (this.OptionHeight || 1)); // Prevent division by 0;
          //console.log(desktopZoom, numOptionSlots, availableHeight, this.OptionHeight);
          var fontRatio = this.optionFontSize / $store.state.defaults.defaultFontSize;
          this.scrollContainerHeight = this.pxToEm(Math.min(Math.max(numOptionSlots, this.minNumOptionSlots), this.options.length) * this.OptionHeight + (window.devicePixelRatio || 1) * 1.33);
        }
      },
      toggle: function() {
        var vm = this;
        if (!this.ui.disabled) {
          this.isOpen = !this.isOpen;
          this.didRunOnce = false; //!this.didRunOnce;
          this.isOpen ? this.arrow.classList.add("up") : this.arrow.classList.remove("up");

          if(this.isOpen)
            setTimeout(function() { vm.setScrollContainerHeight(); }, 10);
        }
      },
      close: function() {
        this.isOpen = false;
        this.didRunOnce = false;
        this.arrow.classList.remove("up");
      },
      set: function(val, idx) {
        this.selectedTxt = val;
        this.didSelection = true;
        this.$emit("input", idx);
      }
    }
  };
</script>