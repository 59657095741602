<template>
  <div class="page">
    <vue-headful
      :title="seoTitle"
      :description="seoDescription"
    />
<!--    <h1>{{page.attributes.Title}}</h1>-->
    <BlockManager :blocks="page.attributes.Content" />
  </div>
</template>

<style lang="less" scoped>
.page {
}
</style>

<script>
import Api from "@/Api";
import BlockManager from "@/components/strapi/BlockManager";

export default {
  name: 'strapiPage',
  components: {BlockManager},
  data() {
    return {
      page: null,
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchPageData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  computed: {
    seoTitle() {
      return (this.page !== null && this.page.attributes.page_seo) ? this.page.attributes.page_seo.seo_title : 'Leenattent - Eerlijk lenen tegen lage rente';
    },
    seoDescription() {
      return (this.page !== null && this.page.attributes.page_seo) ? this.page.attributes.page_seo.seo_description : 'Lenen tegen een rente die behoort tot de allerlaagste van Nederland. Leenattent: eerlijk lenen tegen lage rente, sinds 1983.';
    }
  },
  methods: {
    fetchPageData() {
      Api.get(`https://la-api.thewiseminds.com/api/pages?filters[slug][$eq]=${this.$route.params.pageSlug}&populate[]=Content.Paragraphs&populate[]=Content.Image&populate[]=Content.faq_group.faq_items&populate[]=Content.ReadMore&populate[]=Content.table&populate[]=Content.contacts.Image&populate[]=Content.First_column&populate[]=Content.Second_column&populate[]=Content.Content&populate[]=Content.image&populate[]=Content.Partners&populate[]=Content.Partners.Image&populate[]=page_seo&populate[]=Content.blocks.Image&populate[]=Content.Buttons&populate[]=Content.Brochures.File&populate[]=Content.Vacancies&populate[]=Content.Link&populate[]=Content.Links&populate[]=Content.Links.Image&populate[]=Content.Companies&populate[]=Content.Companies.logo`)
        .then((result) => {
          if (result.data.data.length > 0) {
            this.page = result.data.data[0];
          } else {
            this.$route.push({name: 'not-found'});
          }
        })
        .catch((e) => {
          this.$route.push({name: 'not-found'});
        });
    }
  }
}
</script>
