<template>
  <button
    class="btn-big"
    :style="{
      width: pxToEm(width),
      height: pxToEm(height),
      'max-width': '90%',
      margin: '0 auto'
    }"
    @click="clickHandler()"
  >
    <div class="f-bg" :style="{ height: pxToEm(height * 0.145) }"></div>

    <div :style="{ 'margin-top': pxToEm(-height * 0.145), display:'flex', flex:'1 1 100%' }">
      <slot></slot>
    </div>
  </button>
</template>

<style lang="less">
.btn-big {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border: 0;
  font-size: 16px;

  .f-bg {
    transition: all ease-in-out 150ms;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  &.primary {
    background-color: getColor("primaryLight");
    .f-bg {
      background-color: getColor("primaryDefault");
    }
  }

  &.primary-inverted {
    background-color: getColor("primaryDefault");
    .f-bg {
      background-color: getColor("primaryLight");
    }
  }

  &.secondary {
    background-color: #3a3a3a;
    .f-bg {
      background-color: getColor("greenDefault");
    }
  }

  &.secondary-pink {
    background-color: #3a3a3a;
    .f-bg {
      background-color: getColor("primaryLight");
    }
  }

  &.allGreen {
    background-color: getColor("greenDefault");
    .f-bg {
      background-color: #4a983e;
    }
  }

  &.black {
    background-color: #3a3a3a;
  }

  &.inactive {
    opacity: 0.5;
    background-color: #3a3a3a;
    .f-bg {
      background-color: getColor("primaryLight");
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;
    .txt {
      color: white;
    }
  }

  &:hover {
    .f-bg {
      height: 100% !important;
    }
  }
}
</style>

<script>
export default {
  name: "btnBig",
  props: {
    fontSize: Number,
    textSideMargin: Number,
    width: Number,
    height: Number,
    click: null
  },
  methods: {
    pxToEm(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    clickHandler() {
      if (this.click) {
        this.click();
      } else return;
    }
  }
};
</script>
